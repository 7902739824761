[
  {
    "rank": "1",
    "name": "Formula Rossa",
    "region": "Ferrari World Abu Dhabi",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 240,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "2",
    "name": "Kingda Ka",
    "region": "Six Flags Great Adventure",
    "country": "United States",
    "dimensions": [
      {
        "value": 206,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "3",
    "name": "Top Thrill Dragster",
    "region": "Cedar Point",
    "country": "United States",
    "dimensions": [
      {
        "value": 190,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "4",
    "name": "Do-Dodonpa",
    "region": "Fuji-Q Highland",
    "country": "Japan",
    "dimensions": [
      {
        "value": 179.9,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "4",
    "name": "Red Force",
    "region": "Ferrari Land",
    "country": "Spain",
    "dimensions": [
      {
        "value": 179.9,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "6",
    "name": "Superman: Escape from Krypton[note 1]",
    "region": "Six Flags Magic Mountain",
    "country": "United States",
    "dimensions": [
      {
        "value": 160,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "*",
    "name": "Ring Racer",
    "region": "Nürburgring",
    "country": "Germany",
    "dimensions": [
      {
        "value": 160,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "7",
    "name": "Steel Dragon 2000",
    "region": "Nagashima Spa Land",
    "country": "Japan",
    "dimensions": [
      {
        "value": 153,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "7",
    "name": "Fury 325",
    "region": "Carowinds",
    "country": "United States",
    "dimensions": [
      {
        "value": 153,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "9",
    "name": "Millennium Force",
    "region": "Cedar Point",
    "country": "United States",
    "dimensions": [
      {
        "value": 150,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "10",
    "name": "Leviathan",
    "region": "Canada's Wonderland",
    "country": "Canada",
    "dimensions": [
      {
        "value": 148,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  }
]