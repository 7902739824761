[
  {
    "rank": "1",
    "name": "Burj Khalifa",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/9/93/Burj_Khalifa.jpg/100px-Burj_Khalifa.jpg",
    "geo": "25.1972194; 55.2741694",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 828,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "2",
    "name": "Merdeka 118",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Merdeka_118_20220327.jpg/100px-Merdeka_118_20220327.jpg",
    "geo": "3.14167; 101.70056",
    "region": "Kuala Lumpur",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 678.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "3",
    "name": "Shanghai Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Shanghai_-_Shanghai_Tower_-_0003%28cropped%29.jpg/100px-Shanghai_-_Shanghai_Tower_-_0003%28cropped%29.jpg",
    "geo": "31.235500; 121.501000",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 632,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "4",
    "name": "Abraj Al-Bait Clock Tower",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/f/f4/Abraj-al-Bait-Towers.JPG/100px-Abraj-al-Bait-Towers.JPG",
    "geo": "21.41889; 39.82639",
    "region": "Mecca",
    "country": "Saudi Arabia",
    "dimensions": [
      {
        "value": 601,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "5",
    "name": "Ping An International Finance Centre",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Pingan_International_Finance_Center2020.jpg/100px-Pingan_International_Finance_Center2020.jpg",
    "geo": "22.53667; 114.05028",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 599.1,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "6",
    "name": "Lotte World Tower",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/7/71/Lotte_World_Tower_2019.jpg/100px-Lotte_World_Tower_2019.jpg",
    "geo": "37.51250; 127.10250",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 554.5,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "7",
    "name": "One World Trade Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/New_York_%2833224081040%29.jpg/100px-New_York_%2833224081040%29.jpg",
    "geo": "40.7130000; -74.0135000",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 541.3,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "8",
    "name": "Guangzhou CTF Finance Centre",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Guangzhou_Twin_Towers_CTF%28cropped%29.jpg/100px-Guangzhou_Twin_Towers_CTF%28cropped%29.jpg",
    "geo": "23.12028; 113.32056",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 530,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "8",
    "name": "Tianjin CTF Finance Centre",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Tianjin_CTF_Finance_Centre%2C_24_of_24%28cropped%29.jpg/100px-Tianjin_CTF_Finance_Centre%2C_24_of_24%28cropped%29.jpg",
    "geo": "39.0213806; 117.6984611",
    "region": "Tianjin",
    "country": "China",
    "dimensions": [
      {
        "value": 530,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "10",
    "name": "China Zun",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Parkview_Green_and_CBD_skyline_%2820210927131419%29_2_1_1_1.jpg/100px-Parkview_Green_and_CBD_skyline_%2820210927131419%29_2_1_1_1.jpg",
    "geo": "39.9115111; 116.4602306",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 527.7,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "11",
    "name": "Taipei 101",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Tower_of_Taipei_101%28cropped%29.jpg/100px-Tower_of_Taipei_101%28cropped%29.jpg",
    "geo": "25.03361; 121.56500",
    "region": "Taipei",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 508,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "12",
    "name": "Shanghai World Financial Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/%E4%B8%8A%E6%B5%B7%E5%9B%BD%E9%99%85%E9%87%91%E8%9E%8D%E4%B8%AD%E5%BF%83.jpg/100px-%E4%B8%8A%E6%B5%B7%E5%9B%BD%E9%99%85%E9%87%91%E8%9E%8D%E4%B8%AD%E5%BF%83.jpg",
    "geo": "31.23667; 121.50278",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 492,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "13",
    "name": "International Commerce Centre",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/International_Commerce_Centre_201008.jpg/100px-International_Commerce_Centre_201008.jpg",
    "geo": "22.3033917; 114.1601694",
    "region": "Hong Kong",
    "country": "China",
    "dimensions": [
      {
        "value": 484,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "14",
    "name": "Wuhan Greenland Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Wuhan_Greenland_Center_201704.jpg/100px-Wuhan_Greenland_Center_201704.jpg",
    "geo": "30.58472; 114.31444",
    "region": "Wuhan",
    "country": "China",
    "dimensions": [
      {
        "value": 475.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "15",
    "name": "Central Park Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/Central_Park_Tower_April_2021.jpg/100px-Central_Park_Tower_April_2021.jpg",
    "geo": "40.766333; -73.980944",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 472.4,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "16",
    "name": "Lakhta Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/2018_SPb_Lakhta_Center_02.jpg/100px-2018_SPb_Lakhta_Center_02.jpg",
    "geo": "59.9870306; 30.1781389",
    "region": "Saint Petersburg",
    "country": "Russia",
    "dimensions": [
      {
        "value": 462,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "17",
    "name": "Landmark 81",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/LM81_NhonHuynh_5-8-2018.jpg/100px-LM81_NhonHuynh_5-8-2018.jpg",
    "geo": "10.79500; 106.72194",
    "region": "Ho Chi Minh City",
    "country": "Vietnam",
    "dimensions": [
      {
        "value": 461.2,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "18",
    "name": "Changsha IFS Tower T1",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/%E4%B8%96%E8%8C%82%E7%8E%AF%E7%90%83%E9%87%91%E8%9E%8D%E4%B8%AD%E5%BF%83_IFS%E5%9B%BD%E9%87%91%E4%B8%AD%E5%BF%83_%E8%A5%BF%E4%BE%A7.jpg/100px-%E4%B8%96%E8%8C%82%E7%8E%AF%E7%90%83%E9%87%91%E8%9E%8D%E4%B8%AD%E5%BF%83_IFS%E5%9B%BD%E9%87%91%E4%B8%AD%E5%BF%83_%E8%A5%BF%E4%BE%A7.jpg",
    "geo": "28.1954194; 112.9734194",
    "region": "Changsha",
    "country": "China",
    "dimensions": [
      {
        "value": 452.1,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "19",
    "name": "Petronas Tower 1",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/The_Twins_SE_Asia_2019_%2849171985716%29_%28cropped%29_2.jpg/100px-The_Twins_SE_Asia_2019_%2849171985716%29_%28cropped%29_2.jpg",
    "geo": "3.157583; 101.711417",
    "region": "Kuala Lumpur",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 451.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "19",
    "name": "Petronas Tower 2",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/The_Twins_SE_Asia_2019_%2849171985716%29_%28cropped%29_2.jpg/100px-The_Twins_SE_Asia_2019_%2849171985716%29_%28cropped%29_2.jpg",
    "geo": "3.158222; 101.712139",
    "region": "Kuala Lumpur",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 451.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "21",
    "name": "Zifeng Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Zifeng_Tower_2017.jpg/100px-Zifeng_Tower_2017.jpg",
    "geo": "32.062472; 118.778056",
    "region": "Nanjing",
    "country": "China",
    "dimensions": [
      {
        "value": 450,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "21",
    "name": "Suzhou IFS",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Suzhou_IFS_27-10-2018%2802%29.jpg/100px-Suzhou_IFS_27-10-2018%2802%29.jpg",
    "geo": "31.324111; 120.712806",
    "region": "Suzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 450,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "23",
    "name": "The Exchange 106",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/The_Exchange_106_skyline_Nov_of_2021.jpg/100px-The_Exchange_106_skyline_Nov_of_2021.jpg",
    "geo": "3.1419000; 101.7187500",
    "region": "Kuala Lumpur",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 445.5,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "24",
    "name": "Wuhan Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Wuhan_Center_201704.jpg/100px-Wuhan_Center_201704.jpg",
    "geo": "30.5966500; 114.2396694",
    "region": "Wuhan",
    "country": "China",
    "dimensions": [
      {
        "value": 443.1,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "25",
    "name": "Willis Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Sears_Tower1.JPG/100px-Sears_Tower1.JPG",
    "geo": "41.8786111; -87.6358306",
    "region": "Chicago",
    "country": "United States",
    "dimensions": [
      {
        "value": 442.1,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "26",
    "name": "KK100",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/KK100_exterior_southeast.jpg/100px-KK100_exterior_southeast.jpg",
    "geo": "22.5458306; 114.1015611",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 441.8,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "27",
    "name": "Guangzhou International Finance Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Guangzhou_Twin_Towers_IFC%28cropped%29.jpg/100px-Guangzhou_Twin_Towers_IFC%28cropped%29.jpg",
    "geo": "23.1203472; 113.3180750",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 438.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "28",
    "name": "111 West 57th Street",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/111_West_57th_Street.png/100px-111_West_57th_Street.png",
    "geo": "40.764889; -73.977500",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 435.3,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "29",
    "name": "One Vanderbilt",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/One_Vanderbilt_April_2021.jpg/100px-One_Vanderbilt_April_2021.jpg",
    "geo": "40.75750; -73.97306",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 427,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "30",
    "name": "432 Park Avenue",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/432_Park_Avenue%2C_NY_%28cropped%29.jpg/100px-432_Park_Avenue%2C_NY_%28cropped%29.jpg",
    "geo": "40.76167; -73.97194",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 425.7,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "31",
    "name": "Marina 101",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/6/60/Marina_101.jpg/100px-Marina_101.jpg",
    "geo": "25.0889806; 55.1486306",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 425,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "32",
    "name": "Trump International Hotel and Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/20090518_Trump_International_Hotel_and_Tower%2C_Chicago_2.jpg/100px-20090518_Trump_International_Hotel_and_Tower%2C_Chicago_2.jpg",
    "geo": "41.8891000; -87.6266722",
    "region": "Chicago",
    "country": "United States",
    "dimensions": [
      {
        "value": 423.2,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "33",
    "name": "Dongguan International Trade Center 1",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/%E6%9D%B1%E8%8E%9E%E5%9C%8B%E9%9A%9B%E8%B2%BF%E6%98%93%E4%B8%AD%E5%BF%83-2022.jpg/100px-%E6%9D%B1%E8%8E%9E%E5%9C%8B%E9%9A%9B%E8%B2%BF%E6%98%93%E4%B8%AD%E5%BF%83-2022.jpg",
    "geo": "23.02500; 113.74833",
    "region": "Dongguan",
    "country": "China",
    "dimensions": [
      {
        "value": 422.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "34",
    "name": "Jin Mao Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Jin_Mao_Tower.jpg/100px-Jin_Mao_Tower.jpg",
    "geo": "31.23722; 121.50139",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 420.5,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "35",
    "name": "Princess Tower",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/8/8f/Princess_Tower.jpg/100px-Princess_Tower.jpg",
    "geo": "25.0886250; 55.1468583",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 413.4,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "36",
    "name": "Al Hamra Tower",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/4/42/Al_Hamra_Tower.jpg/100px-Al_Hamra_Tower.jpg",
    "geo": "29.37861; 47.99278",
    "region": "Kuwait City",
    "country": "Kuwait",
    "dimensions": [
      {
        "value": 412.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "37",
    "name": "Two International Finance Centre",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/IFC%2C_Hong_Kong_Island_%282796343561%29%28cropped%29.jpg/100px-IFC%2C_Hong_Kong_Island_%282796343561%29%28cropped%29.jpg",
    "geo": "22.28500; 114.15917",
    "region": "Hong Kong",
    "country": "China",
    "dimensions": [
      {
        "value": 412,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "38",
    "name": "Haeundae LCT The Sharp Landmark Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Haeundae_Beach_20200522_012.jpg/100px-Haeundae_Beach_20200522_012.jpg",
    "geo": "35.16139; 129.17389",
    "region": "Busan",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 411.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "39",
    "name": "Guangxi China Resources Tower",
    "geo": "22.814833; 108.391139",
    "region": "Nanning",
    "country": "China",
    "dimensions": [
      {
        "value": 402.7,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "40",
    "name": "Guiyang International Financial Center T1",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Guizhou_Financial_City_District.jpg/100px-Guizhou_Financial_City_District.jpg",
    "geo": "26.65094889; 106.64453389",
    "region": "Guiyang",
    "country": "China",
    "dimensions": [
      {
        "value": 401,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "41",
    "name": "China Resources Headquarters",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/China_Resources_Headquarters_in_Dec_2020.jpg/100px-China_Resources_Headquarters_in_Dec_2020.jpg",
    "geo": "22.5177611; 113.9416000",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 392.5,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "42",
    "name": "23 Marina",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/5/59/23_Marina.jpg/50px-23_Marina.jpg",
    "geo": "25.0898111; 55.1506111",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 392.4,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "43",
    "name": "CITIC Plaza",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/%E4%B8%AD%E4%BF%A1%E5%A4%A7%E5%8E%A6%E5%8D%97%E4%BE%A7_-_panoramio_3.jpg/100px-%E4%B8%AD%E4%BF%A1%E5%A4%A7%E5%8E%A6%E5%8D%97%E4%BE%A7_-_panoramio_3.jpg",
    "geo": "23.14444; 113.31944",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 390.2,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "44",
    "name": "Shum Yip Upperhills Tower 1",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Shum_Yip_Upperhills_Tower_in_Jan2021.jpg/100px-Shum_Yip_Upperhills_Tower_in_Jan2021.jpg",
    "geo": "22.55861; 114.06611",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 388.1,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "45",
    "name": "Chengmai Center",
    "geo": "22.56083; 114.10056",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 388,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "46",
    "name": "30 Hudson Yards",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Hudson_Yards_from_Hudson_Commons_%2895131p%29_%2830_Hudson_Yards%29.jpg/100px-Hudson_Yards_from_Hudson_Commons_%2895131p%29_%2830_Hudson_Yards%29.jpg",
    "geo": "40.75861; -73.99861",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 387.1,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "47",
    "name": "Capital Market Authority Tower",
    "geo": "24.76278; 46.64028",
    "region": "Riyadh",
    "country": "Saudi Arabia",
    "dimensions": [
      {
        "value": 385,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "47",
    "name": "Iconic Tower",
    "geo": "30.0100806; 31.6957694",
    "region": "Cairo",
    "country": "Egypt",
    "dimensions": [
      {
        "value": 385,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "49",
    "name": "Shun Hing Square",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Shun_Hing_Square_exterior_southwest2021.jpg/100px-Shun_Hing_Square_exterior_southwest2021.jpg",
    "geo": "22.54528; 114.10583",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 384,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "50",
    "name": "Eton Place Dalian Tower 1",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Eton_Place_Dalian_Tower%2C_Aug2018%28cropped%292.jpg/100px-Eton_Place_Dalian_Tower%2C_Aug2018%28cropped%292.jpg",
    "region": "Dalian",
    "country": "China",
    "dimensions": [
      {
        "value": 383.2,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "51",
    "name": "Autograph Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Thamrin_Nine_projects%2C_May_2020.png/100px-Thamrin_Nine_projects%2C_May_2020.png",
    "geo": "-6.19861; 106.82222",
    "region": "Jakarta",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 382.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "52",
    "name": "Logan Century Center 1",
    "geo": "22.811722; 108.394778",
    "region": "Nanning",
    "country": "China",
    "dimensions": [
      {
        "value": 381.3,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "53",
    "name": "Burj Mohammed bin Rashid",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Burj_Mohammed_Bin_Rashid.jpg/100px-Burj_Mohammed_Bin_Rashid.jpg",
    "geo": "24.48806; 54.35639",
    "region": "Abu Dhabi",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 381.2,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "54",
    "name": "Empire State Building",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Empire_State_Building_cropped.jpg/100px-Empire_State_Building_cropped.jpg",
    "geo": "40.74833; -73.98528",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 381,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "55",
    "name": "Elite Residence",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Elite_Residence_%28skyscraper%29_in_2016.jpg/50px-Elite_Residence_%28skyscraper%29_in_2016.jpg",
    "geo": "25.0895611; 55.1478889",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 380.5,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "56",
    "name": "Riverview Plaza",
    "geo": "30.610778; 114.303583",
    "region": "Wuhan",
    "country": "China",
    "dimensions": [
      {
        "value": 376,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "57",
    "name": "Dabaihui Plaza",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Dabaihui_Plaza2021.jpg/100px-Dabaihui_Plaza2021.jpg",
    "geo": "22.53889; 114.05889",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 375.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "58",
    "name": "Central Plaza",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Hong_Kong_Convention_and_Exhibition_Centre_200906%28cropped%29.jpg/100px-Hong_Kong_Convention_and_Exhibition_Centre_200906%28cropped%29.jpg",
    "geo": "22.28000; 114.17361",
    "region": "Hong Kong",
    "country": "China",
    "dimensions": [
      {
        "value": 373.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "59",
    "name": "Federation Tower (East Tower)",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Federation-Tower_in_July%2C_Moscow.jpg/100px-Federation-Tower_in_July%2C_Moscow.jpg",
    "geo": "55.749861; 37.537778",
    "region": "Moscow",
    "country": "Russia",
    "dimensions": [
      {
        "value": 373.7,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "60",
    "name": "Dalian International Trade Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Dalian_International_Trade_Center%2C_Aug2018_%28cropped%29.jpg/100px-Dalian_International_Trade_Center%2C_Aug2018_%28cropped%29.jpg",
    "geo": "38.919611; 121.633000",
    "region": "Dalian",
    "country": "China",
    "dimensions": [
      {
        "value": 370.2,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "61",
    "name": "Address Boulevard",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/2/2a/The_Address_Boulevard.jpg/100px-The_Address_Boulevard.jpg",
    "geo": "25.2009111; 55.2762306",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 370,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "62",
    "name": "Haitian Center Tower 2",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Qingdao_Haitian_Center_in_June_2021.jpg/100px-Qingdao_Haitian_Center_in_June_2021.jpg",
    "geo": "36.05639; 120.36472",
    "region": "Qingdao",
    "country": "China",
    "dimensions": [
      {
        "value": 368.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "63",
    "name": "Golden Eagle Tiandi Tower A",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/%E5%8D%97%E4%BA%AC%E6%B2%B3%E8%A5%BF%E9%87%91%E9%B9%B0%E5%A4%A7%E6%A5%BC.jpg/100px-%E5%8D%97%E4%BA%AC%E6%B2%B3%E8%A5%BF%E9%87%91%E9%B9%B0%E5%A4%A7%E6%A5%BC.jpg",
    "geo": "32.0260389; 118.7348806",
    "region": "Nanjing",
    "country": "China",
    "dimensions": [
      {
        "value": 368.1,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "64",
    "name": "Bank of China Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/HK_Bank_of_China_Tower_View.jpg/100px-HK_Bank_of_China_Tower_View.jpg",
    "geo": "22.27917; 114.16139",
    "region": "Hong Kong",
    "country": "China",
    "dimensions": [
      {
        "value": 367,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "65",
    "name": "Bank of America Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/OBP_-_Ext_-_42nd_East.jpg/100px-OBP_-_Ext_-_42nd_East.jpg",
    "geo": "40.7552806; -73.9841694",
    "region": "New York City",
    "country": "United States",
    "dimensions": [
      {
        "value": 365.8,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "66",
    "name": "St. Regis Chicago",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/St_Regis_Chicago.jpg/100px-St_Regis_Chicago.jpg",
    "geo": "41.88333; -87.61611",
    "region": "Chicago",
    "country": "United States",
    "dimensions": [
      {
        "value": 362.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "67",
    "name": "Almas Tower",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/4/4f/Almas_Tower.jpg/100px-Almas_Tower.jpg",
    "geo": "25.0688306; 55.1411306",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 360,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "68",
    "name": "Hanking Center",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Hanking_Center2021.jpg/100px-Hanking_Center2021.jpg",
    "geo": "22.54556; 113.93444",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 358.9,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "69",
    "name": "Greenland Group Suzhou Center",
    "geo": "31.135750; 120.584139",
    "region": "Suzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 358,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "70",
    "name": "Gevora Hotel",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/f/f3/Gevora_Hotel.jpg/100px-Gevora_Hotel.jpg",
    "geo": "25.2125194; 55.2768111",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 356.3,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "71",
    "name": "JW Marriott Marquis Dubai Tower 1",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/d/dc/JW_Marriott_Marquis_Dubai2.jpg/100px-JW_Marriott_Marquis_Dubai2.jpg",
    "geo": "25.186000; 55.258444",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 355.4,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "71",
    "name": "JW Marriott Marquis Dubai Tower 2",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/d/dc/JW_Marriott_Marquis_Dubai2.jpg/100px-JW_Marriott_Marquis_Dubai2.jpg",
    "geo": "25.185306; 55.257778",
    "region": "2013",
    "country": "",
    "dimensions": [
      {
        "value": 355.4,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "73",
    "name": "Emirates Office Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Emirates_Towers_in_Dubai_at_dawn.jpg/100px-Emirates_Towers_in_Dubai_at_dawn.jpg",
    "geo": "25.21750; 55.28333",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 354.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "74",
    "name": "Raffles City Chongqing T3N",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Raffles_City_Chongqing_from_Yangtze_River%28cropped%29.jpg/100px-Raffles_City_Chongqing_from_Yangtze_River%28cropped%29.jpg",
    "geo": "29.568222; 106.583750",
    "region": "Chongqing",
    "country": "China",
    "dimensions": [
      {
        "value": 354.5,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "74",
    "name": "Raffles City Chongqing T4N",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Raffles_City_Chongqing_from_Yangtze_River%28cropped%29.jpg/100px-Raffles_City_Chongqing_from_Yangtze_River%28cropped%29.jpg",
    "geo": "29.568222; 106.583750",
    "region": "Chongqing",
    "country": "China",
    "dimensions": [
      {
        "value": 354.5,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "76",
    "name": "OKO – South Tower",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Oko_towers1.jpg/100px-Oko_towers1.jpg",
    "geo": "55.749361; 37.534083",
    "region": "Moscow",
    "country": "Russia",
    "dimensions": [
      {
        "value": 354.2,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "77",
    "name": "The Marina Torch",
    "image": "https://upload.wikimedia.org/wikipedia/en/thumb/b/bd/The_Marina_Torch.jpg/60px-The_Marina_Torch.jpg",
    "geo": "25.0879806; 55.1474306",
    "region": "Dubai",
    "country": "United Arab Emirates",
    "dimensions": [
      {
        "value": 352,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "78",
    "name": "Forum 66 Tower 1",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Forum_66%2C_Shenyang%2C_Oct2014.png/100px-Forum_66%2C_Shenyang%2C_Oct2014.png",
    "geo": "41.79944; 123.42750",
    "region": "Shenyang",
    "country": "China",
    "dimensions": [
      {
        "value": 350.6,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "79",
    "name": "The Pinnacle",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/%E8%8A%B1%E5%9F%8E%E6%B1%87_%E9%9F%B3%E4%B9%90%E5%96%B7%E6%B3%89_%282013-10-26%29_-_panoramio_Pinnacle%28cropped%29.jpg/100px-%E8%8A%B1%E5%9F%8E%E6%B1%87_%E9%9F%B3%E4%B9%90%E5%96%B7%E6%B3%89_%282013-10-26%29_-_panoramio_Pinnacle%28cropped%29.jpg",
    "geo": "23.127806; 113.318000",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 350.3,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "80",
    "name": "Xi'an Glory International Financial Center",
    "geo": "34.1947000; 108.8778611",
    "region": "Xi'an",
    "country": "China",
    "dimensions": [
      {
        "value": 350,
        "unit": "meters",
        "type": "height"
      }
    ]
  }
]