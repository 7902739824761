[
  {
    "rank": 1,
    "name": "International Space Station",
    "notes": "Space station",
    "dimensions": [
      {
        "value": 440725,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 2,
    "name": "Mir",
    "notes": "Soviet / Russian space station",
    "dimensions": [
      {
        "value": 129700,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 3,
    "name": "Space Shuttle",
    "notes": "Space Shuttle Atlantis on STS-117, the heaviest flight of the Space Shuttle.",
    "dimensions": [
      {
        "value": 122683,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 4,
    "name": "Buran",
    "notes": "Soviet reusable orbiter, made single flight",
    "dimensions": [
      {
        "value": 105000,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 5,
    "name": "Skylab",
    "notes": "U.S. space station; largest station orbited in one launch",
    "dimensions": [
      {
        "value": 77111,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 6,
    "name": "Apollo CSM",
    "notes": "U.S. crewed spacecraft for entering lunar orbit",
    "dimensions": [
      {
        "value": 28800,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 7,
    "name": "Tianhe",
    "notes": "Chinese space station",
    "dimensions": [
      {
        "value": 22500,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 8,
    "name": "ATV",
    "notes": "European cargo spacecraft on its heaviest flight",
    "dimensions": [
      {
        "value": 20293,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 9,
    "name": "Salyut 7",
    "notes": "USSR space station",
    "dimensions": [
      {
        "value": 19824,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 10,
    "name": "KH-11",
    "notes": "Electro-optical reconnaissance satellite",
    "dimensions": [
      {
        "value": 19600,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 11,
    "name": "Salyut 1",
    "notes": "USSR space station",
    "dimensions": [
      {
        "value": 18425,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 12,
    "name": "TKS",
    "notes": "Soviet crewed spacecraft",
    "dimensions": [
      {
        "value": 17510,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 13,
    "name": "Proton satellite",
    "notes": "Space research satellite",
    "dimensions": [
      {
        "value": 17000,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 14,
    "name": "Apollo Lunar Module",
    "notes": "U.S. crewed lunar lander",
    "dimensions": [
      {
        "value": 16400,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 15,
    "name": "Compton Gamma Ray Observatory",
    "notes": "Space observatory",
    "dimensions": [
      {
        "value": 16329,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 16,
    "name": "Lacrosse",
    "notes": "Radar imaging reconnaissance satellite",
    "dimensions": [
      {
        "value": 14500,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 17,
    "name": "Hubble Space Telescope",
    "notes": "Space observatory",
    "dimensions": [
      {
        "value": 11110,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 18,
    "name": "Tiangong-2",
    "notes": "Chinese space station",
    "dimensions": [
      {
        "value": 8600,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 19,
    "name": "Tiangong-1",
    "notes": "Chinese space station",
    "dimensions": [
      {
        "value": 8506,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 20,
    "name": "Envisat",
    "notes": "Earth observing satellite Kessler syndrome threat",
    "dimensions": [
      {
        "value": 8211,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 21,
    "name": "Shijian-20",
    "notes": "DFH-5 satellite bus, Communication Technology Test Satellite",
    "dimensions": [
      {
        "value": 8000,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 22,
    "name": "Heaviest commercial\ngeosynchronous\ncommunication satellites",
    "notes": "Communications satellite",
    "dimensions": [
      {
        "value": 7000,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 23,
    "name": "Soyuz",
    "notes": "Russian crewed spacecraft (latest revision used for mass)",
    "dimensions": [
      {
        "value": 7080,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 24,
    "name": "Telstar 19V",
    "notes": "Communications satellite",
    "dimensions": [
      {
        "value": 7075,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 25,
    "name": "TerreStar-1",
    "notes": "Communications satellite",
    "dimensions": [
      {
        "value": 6910,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 26,
    "name": "EchoStar XXI",
    "notes": "Communications satellite",
    "dimensions": [
      {
        "value": 6871,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 27,
    "name": "UARS",
    "notes": "Earth science",
    "dimensions": [
      {
        "value": 6540,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 28,
    "name": "James Webb Space Telescope",
    "notes": "Space observatory",
    "dimensions": [
      {
        "value": 6161.4,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 29,
    "name": "Chandra X-ray Obs.",
    "notes": "Space observatory",
    "dimensions": [
      {
        "value": 5865,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 30,
    "name": "GSAT-11",
    "notes": "Heaviest Indian communications satellite",
    "dimensions": [
      {
        "value": 5854,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 31,
    "name": "Cassini-Huygens",
    "notes": "Saturn orbiter and Titan probe",
    "dimensions": [
      {
        "value": 5655,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 32,
    "name": "Tianwen-1",
    "notes": "Tianwen-1 Mars orbiter, deployable and remote cameras, lander and Zhurong rover",
    "dimensions": [
      {
        "value": 5000,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 33,
    "name": "Terra",
    "notes": "Earth observing satellite",
    "dimensions": [
      {
        "value": 4864,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 34,
    "name": "ExoMars Trace Gas Orbiter",
    "notes": "Mars orbiter (including Schiaparelli EDM lander)",
    "dimensions": [
      {
        "value": 4332,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 35,
    "name": "GPS IIIA",
    "notes": "Current GPS satellite series",
    "dimensions": [
      {
        "value": 3880,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 36,
    "name": "Spektr-R (RadioAstron)",
    "notes": "Space observatory",
    "dimensions": [
      {
        "value": 3660,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 37,
    "name": "Juno",
    "notes": "Jupiter orbiter",
    "dimensions": [
      {
        "value": 3625,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 38,
    "name": "Herschel",
    "notes": "Space observatory",
    "dimensions": [
      {
        "value": 3400,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 39,
    "name": "Galileo",
    "notes": "Jupiter orbiter and probe",
    "dimensions": [
      {
        "value": 2562,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 40,
    "name": "MAVEN",
    "notes": "Mars orbiter",
    "dimensions": [
      {
        "value": 2454,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 41,
    "name": "Lunar Reconnaissance Orbiter",
    "notes": "Lunar orbiter",
    "dimensions": [
      {
        "value": 1846,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 42,
    "name": "Astrosat",
    "notes": "India's first dedicated multi wavelength space telescope-space observatory",
    "dimensions": [
      {
        "value": 1513,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 43,
    "name": "Mars Orbiter Mission",
    "notes": "India's first Mars mission",
    "dimensions": [
      {
        "value": 1337.2,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 44,
    "name": "Venus Express",
    "notes": "Venus orbiter",
    "dimensions": [
      {
        "value": 1270,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 45,
    "name": "MESSENGER",
    "notes": "Mercury orbiter",
    "dimensions": [
      {
        "value": 1093,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 46,
    "name": "Voyager 1 / Voyager 2",
    "notes": "Outer planets / interstellar space",
    "dimensions": [
      {
        "value": 815,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  },
  {
    "rank": 47,
    "name": "New Horizons",
    "notes": "Pluto/Kuiper belt probe",
    "dimensions": [
      {
        "value": 465,
        "unit": "kilogram",
        "type": "mass"
      }
    ]
  }
]