[
  {
    "rank": 1,
    "name": "Delaware Aqueduct",
    "region": "New York State",
    "country": "United States",
    "dimensions": [
      {
        "value": 137000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 2,
    "name": "Päijänne Water Tunnel",
    "region": "Southern Finland",
    "country": "Finland",
    "dimensions": [
      {
        "value": 120000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 3,
    "name": "Dahuofang Water Tunnel",
    "region": "Liaoning",
    "country": "China",
    "dimensions": [
      {
        "value": 85320,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 4,
    "name": "Orange–Fish River Tunnel",
    "country": "South Africa",
    "dimensions": [
      {
        "value": 82800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 5,
    "name": "Bolmen Water Tunnel",
    "region": "Kronoberg/Scania",
    "country": "Sweden",
    "dimensions": [
      {
        "value": 82000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 6,
    "name": "Chengdu Metro Line 6",
    "region": "Chengdu",
    "country": "China",
    "dimensions": [
      {
        "value": 68223,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 7,
    "name": "Neelum Jhelum HydroPower Tunnel",
    "region": "Muzaffarabad",
    "country": "Azad Kashmir",
    "dimensions": [
      {
        "value": 68000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 8,
    "name": "Tunel Emisor Oriente",
    "region": "Mexico City",
    "country": "Mexico",
    "dimensions": [
      {
        "value": 62500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 9,
    "name": "Guangzhou Metro Line 18",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 58300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 10,
    "name": "Guangzhou Metro Line 3",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 57930,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 11,
    "name": "Gotthard Base Tunnel",
    "region": "Central Swiss Alps",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 57104,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 12,
    "name": "Beijing Subway Line 10",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 57100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 13,
    "name": "Beijing Subway Line 6",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 53400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 14,
    "name": "Seikan Tunnel",
    "region": "Tsugaru Strait",
    "country": "Japan",
    "dimensions": [
      {
        "value": 53850,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 15,
    "name": "Želivka Water Tunnel",
    "region": "Central Bohemian Region",
    "country": "Czech Republic",
    "dimensions": [
      {
        "value": 51970,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 16,
    "name": "Seoul Subway: Line 5",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 51700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 17,
    "name": "Channel Tunnel",
    "region": "English Channel",
    "country": "United Kingdom/France",
    "dimensions": [
      {
        "value": 50450,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 18,
    "name": "Yulhyeon Tunnel",
    "region": "Seoul Capital Area",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 50300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 19,
    "name": "River Arpa – Lake Sevan tunnel",
    "country": "Armenia (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 48358,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 20,
    "name": "Pahang – Selangor Raw Water Transfer Project",
    "region": "Pahang & Selangor",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 44600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 21,
    "name": "Wuhan Metro Line 2",
    "region": "Wuhan",
    "country": "China",
    "dimensions": [
      {
        "value": 44347,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 22,
    "name": "#1 Tunnel, Yellow River Diversion to Shanxi North Line",
    "region": "Shanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 43670,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 23,
    "name": "#7 Tunnel, Yellow River Diversion to Shanxi South Line",
    "region": "Shanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 43500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 24,
    "name": "Beijing Subway Line 8",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 42600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 25,
    "name": "Shanghai Metro Line 15",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 42300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 26,
    "name": "Chengdu Metro Line 5",
    "region": "Chengdu",
    "country": "China",
    "dimensions": [
      {
        "value": 42300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 27,
    "name": "Beijing Subway Line 14",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 42000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 28,
    "name": "Tianjin Metro Line 6",
    "region": "Tianjin",
    "country": "China",
    "dimensions": [
      {
        "value": 42000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 29,
    "name": "Downtown line",
    "region": "MRT",
    "country": "Singapore",
    "dimensions": [
      {
        "value": 41900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 30,
    "name": "Nanjing Metro Line 3",
    "region": "Nanjing",
    "country": "China",
    "dimensions": [
      {
        "value": 41567,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 31,
    "name": "Serpukhovsko-Timiryazevskaya Line (Altufyevo – Bulvar Dmitriya Donskogo)",
    "region": "Moscow Metro",
    "country": "Russia (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 41500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 32,
    "name": "Suzhou Rail Transit: Line 4",
    "region": "Suzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 41500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 33,
    "name": "Madrid Metro: Line 12 (MetroSur)",
    "region": "Madrid",
    "country": "Spain",
    "dimensions": [
      {
        "value": 40900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 34,
    "name": "Tocho-mae - Shiodome - Hikarigaoka (Toei Oedo Line)",
    "region": "Tokyo",
    "country": "Japan",
    "dimensions": [
      {
        "value": 40700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 35,
    "name": "Shanghai Subway: Line 7",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 40200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 36,
    "name": "Kárahnjúkar Hydropower Plant",
    "region": "Fljótsdalshreppur",
    "country": "Iceland",
    "dimensions": [
      {
        "value": 39700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 37,
    "name": "Quabbin Aqueduct",
    "region": "Massachusetts",
    "country": "United States",
    "dimensions": [
      {
        "value": 39600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 38,
    "name": "Chengdu Metro Line 4",
    "region": "Chengdu",
    "country": "China",
    "dimensions": [
      {
        "value": 39300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 39,
    "name": "Chengdu Metro Line 7",
    "region": "Chengdu",
    "country": "China",
    "dimensions": [
      {
        "value": 38600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 40,
    "name": "Seoul Subway: Line 3 (Apgujeong-Ogeum)",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 38200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 41,
    "name": "Kaluzhsko-Rizhskaya Line (Novoyasenevskaya – Medvedkovo)",
    "region": "Moscow Metro",
    "country": "Russia (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 37800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 42,
    "name": "Shenzhen Subway: Luobao Line",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 37497,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 43,
    "name": "Chengdu Metro Line 1",
    "region": "Chengdu",
    "country": "China",
    "dimensions": [
      {
        "value": 37470,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 44,
    "name": "Harold D. Roberts Tunnel",
    "region": "Colorado",
    "country": "United States",
    "dimensions": [
      {
        "value": 37464,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 45,
    "name": "Shuakhevi Hydropower Plant",
    "region": "Shuakhevi",
    "country": "Adjara",
    "dimensions": [
      {
        "value": 37400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 46,
    "name": "Shenzhen Subway: Shekou Line",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 36146,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 47,
    "name": "Dawushan Tunnel, Niulan River Diversion to Dianchi",
    "region": "Yunnan",
    "country": "China",
    "dimensions": [
      {
        "value": 36137,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 48,
    "name": "Circle line",
    "region": "MRT",
    "country": "Singapore",
    "dimensions": [
      {
        "value": 35700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 49,
    "name": "Busan Metro: Line 2 (Dongwon-Jangsan)",
    "region": "Busan",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 35500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 50,
    "name": "Songshan Lake Tunnel",
    "region": "Dongguan",
    "country": "China",
    "dimensions": [
      {
        "value": 35391,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 51,
    "name": "Seoul Subway: Line 6",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 35100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 52,
    "name": "Seoul Subway: Line 7 (Cheongdam-Bupyeong-gu Office)",
    "region": "Seoul",
    "country": "Incheon",
    "dimensions": [
      {
        "value": 35100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 53,
    "name": "Tianjin Metro: Line 5",
    "region": "Tianjin",
    "country": "China",
    "dimensions": [
      {
        "value": 34800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 54,
    "name": "Lötschberg Base Tunnel",
    "region": "Bernese Alps",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 34577,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 55,
    "name": "Tyne-Tees Tunnel",
    "region": "England",
    "country": "United Kingdom",
    "dimensions": [
      {
        "value": 34000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 56,
    "name": "Guangzhou Metro: Line 8",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 33900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 57,
    "name": "Lyublinsko-Dmitrovskaya Line (Zyablikovo – Seligerskaya)",
    "region": "Moscow Metro",
    "country": "Russia (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 33400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 58,
    "name": "Madrid Metro: Line 7",
    "region": "Madrid",
    "country": "Spain",
    "dimensions": [
      {
        "value": 32919,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 59,
    "name": "New Guanjiao Tunnel",
    "region": "Qinghai",
    "country": "China",
    "dimensions": [
      {
        "value": 32645,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 60,
    "name": "Meijo Line - Meikō Line",
    "region": "Nagoya Municipal Subway",
    "country": "Japan",
    "dimensions": [
      {
        "value": 32400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 61,
    "name": "Guangzhou Metro: Line 2",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 32000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 62,
    "name": "Rathaus Spandau-Rudow (U7)",
    "region": "Berlin U-Bahn",
    "country": "Germany",
    "dimensions": [
      {
        "value": 31800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 63,
    "name": "Gerede Water Transmission Tunnel",
    "region": "Ankara",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 31592,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 64,
    "name": "Seoul Subway: Bundang Line (Seoul Forest-Jukjeon)",
    "region": "Seoul",
    "country": "Seongnam and Yongin in South Korea",
    "dimensions": [
      {
        "value": 31400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 65,
    "name": "Daegu Metro: Line 2",
    "country": "Daegu and Gyeongsan in South Korea",
    "dimensions": [
      {
        "value": 31400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 66,
    "name": "Milwaukee Metropolitan Sewerage District Deep Tunnel Phase 1",
    "region": "Milwaukee County",
    "country": "Wisconsin",
    "dimensions": [
      {
        "value": 31221,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 67,
    "name": "Beijing Subway: Line 1",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 31040,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 68,
    "name": "Côte-Vertu - Montmorency (Line 2 Orange)",
    "region": "Montreal Metro",
    "country": "Canada",
    "dimensions": [
      {
        "value": 30798,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 69,
    "name": "Shanghai Subway: Line 2",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 30187,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 70,
    "name": "Parnas - Kupchino (line 2)",
    "region": "Saint Petersburg Metro",
    "country": "Russia (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 30100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 71,
    "name": "Guangzhou Metro: Line 5",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 29900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 72,
    "name": "Shandaken Tunnel",
    "region": "Catskill Mountains",
    "country": "New York",
    "dimensions": [
      {
        "value": 29780,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 73,
    "name": "Shanghai Subway: Line 8",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 29650,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 74,
    "name": "Prospekt Veteranov - Devyatkino (line 1)",
    "region": "Saint Petersburg Metro",
    "country": "Russia (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 29600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 75,
    "name": "Shanghai Subway: Line 10",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 29600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 76,
    "name": "Evinos - Mornos Tunnel",
    "region": "Aetolia-Acarnania",
    "country": "Greece",
    "dimensions": [
      {
        "value": 29400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 77,
    "name": "Chengdu Metro Line 2",
    "region": "Chengdu",
    "country": "China",
    "dimensions": [
      {
        "value": 29000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 78,
    "name": "Hultman Aqueduct",
    "region": "Eastern Massachusetts",
    "country": "United States",
    "dimensions": [
      {
        "value": 28640,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 79,
    "name": "Sydney Metro Northwest",
    "region": "Sydney",
    "country": "Australia",
    "dimensions": [
      {
        "value": 28500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 80,
    "name": "Guadarrama Tunnel",
    "region": "Sierra de Guadarrama",
    "country": "Spain",
    "dimensions": [
      {
        "value": 28377,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 81,
    "name": "MetroWest Water Supply Tunnel",
    "region": "Eastern Massachusetts",
    "country": "United States",
    "dimensions": [
      {
        "value": 28300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 82,
    "name": "West Qinling Tunnel",
    "region": "Gansu",
    "country": "China",
    "dimensions": [
      {
        "value": 28236,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 83,
    "name": "Taipei Metro: Blue Line",
    "region": "Taipei",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 28200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 84,
    "name": "Beijing Subway: Line 4",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 28165,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 85,
    "name": "Seoul Subway: Line 9 (Gimpo Airport-Sports Complex)",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 28100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 86,
    "name": "Taihang Tunnel",
    "region": "Taihang Mountains",
    "country": "China",
    "dimensions": [
      {
        "value": 27848,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 87,
    "name": "Morden - East Finchley (Northern line)",
    "region": "London Underground",
    "country": "England",
    "dimensions": [
      {
        "value": 27800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 88,
    "name": "Shenyang Subway: Line 1",
    "region": "Shenyang",
    "country": "China",
    "dimensions": [
      {
        "value": 27800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 89,
    "name": "Melamchi Water Tunnel",
    "region": "Melamchi to Kathmandu",
    "country": "Nepal",
    "dimensions": [
      {
        "value": 27000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 90,
    "name": "Seoul Subway: Incheon Line 1 (Bakchon-International Business District)",
    "region": "Incheon",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 27000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 91,
    "name": "Dainichi-Nagahara (Tanimachi Line)",
    "region": "Osaka Metro",
    "country": "Japan",
    "dimensions": [
      {
        "value": 26900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 92,
    "name": "LEP Tunnel",
    "region": "CERN",
    "country": "Switzerland/France",
    "dimensions": [
      {
        "value": 26659,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 93,
    "name": "İstanbul Metro (Line M4)",
    "region": "İstanbul",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 26500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 94,
    "name": "Hakkōda Tunnel (Tōhoku Shinkansen)",
    "region": "Hakkōda Mountains",
    "country": "Japan",
    "dimensions": [
      {
        "value": 26455,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 95,
    "name": "Şanlıurfa Irrigation Tunnels",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 26400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 96,
    "name": "Shanghai Subway: Line 9",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 26263,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 97,
    "name": "Gilgel Gibe II Power Station headrace tunnel",
    "country": "Ethiopia",
    "dimensions": [
      {
        "value": 26000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 98,
    "name": "#5 Tunnel, Yellow River Diversion to Shanxi South Line",
    "region": "Shanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 26000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 99,
    "name": "Daegu Metro: Line 1",
    "region": "Daegu",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 25900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 100,
    "name": "MRT Blue Line",
    "region": "Bangkok",
    "country": "Thailand",
    "dimensions": [
      {
        "value": 25800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 101,
    "name": "Iwate-Ichinohe Tunnel",
    "region": "Ōu Mountains",
    "country": "Japan",
    "dimensions": [
      {
        "value": 25810,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 102,
    "name": "Sudbury Aqueduct",
    "region": "Eastern Massachusetts",
    "country": "United States",
    "dimensions": [
      {
        "value": 25750,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 103,
    "name": "Suzhou Rail Transit: Line 1",
    "region": "Suzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 25739,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 104,
    "name": "Taipei Metro: Orange Line (Huilong - Nanshijiao)",
    "region": "Taipei",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 25100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 105,
    "name": "Musil Tunnel",
    "region": "Wonju-Jecheon (Jungang Line)",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 25080,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 106,
    "name": "Lærdal Tunnel",
    "region": "Lærdal - Aurland",
    "country": "Norway",
    "dimensions": [
      {
        "value": 24510,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 107,
    "name": "Yellow Line (Delhi Metro): GTB Nagar - Qutub Minar",
    "region": "Delhi",
    "country": "India",
    "dimensions": [
      {
        "value": 24000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 108,
    "name": "Madrid Metro: Line 1",
    "region": "Madrid",
    "country": "Spain",
    "dimensions": [
      {
        "value": 23876,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 109,
    "name": "Lainzer/Wienerwaldtunnel",
    "region": "west of Vienna",
    "country": "Austria",
    "dimensions": [
      {
        "value": 23844,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 110,
    "name": "Harbour Area Treatment Scheme (HATS) Stage 1",
    "region": "Tseung Kwan O",
    "country": "Hong Kong Island",
    "dimensions": [
      {
        "value": 23600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 111,
    "name": "Eucumbene-Snowy Tunnel",
    "region": "NSW",
    "country": "Australia",
    "dimensions": [
      {
        "value": 23500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 112,
    "name": "Madrid Metro: Line 6",
    "region": "Madrid",
    "country": "Spain",
    "dimensions": [
      {
        "value": 23472,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 113,
    "name": "Angrignon - Honoré-Beaugrand (Line 1 Green)",
    "region": "Montreal Metro",
    "country": "Canada",
    "dimensions": [
      {
        "value": 23262,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 114,
    "name": "Warsaw Metro Line 1: Kabaty - Młociny",
    "region": "Warsaw",
    "country": "Poland",
    "dimensions": [
      {
        "value": 23100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 115,
    "name": "Beijing Subway: Line 2",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 23100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 116,
    "name": "Xinma Hydroelectric Power Station Water Tunnel",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 22975,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 117,
    "name": "Iiyama Tunnel",
    "region": "Iiyama",
    "country": "Japan",
    "dimensions": [
      {
        "value": 22225,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 118,
    "name": "Daishimizu Tunnel",
    "region": "Mount Tanigawa",
    "country": "Japan",
    "dimensions": [
      {
        "value": 22221,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 119,
    "name": "Eucumbene-Tumut Tunnel",
    "region": "NSW",
    "country": "Australia",
    "dimensions": [
      {
        "value": 22200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 120,
    "name": "Daegwallyeong Tunnel",
    "country": "Pyeongchang-Gangneung (Gyeonggang Line)",
    "dimensions": [
      {
        "value": 21755,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 121,
    "name": "Vorotan-Arpa Tunnel",
    "country": "Armenia (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 21652,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 122,
    "name": "UNK proton accelerator",
    "region": "Protvino",
    "country": "Russia (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 21000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 123,
    "name": "Guangfo Metro",
    "region": "Foshan",
    "country": "China",
    "dimensions": [
      {
        "value": 20900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 124,
    "name": "Luliangshan Tunnel",
    "region": "Shanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 20785,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 125,
    "name": "Shanghai Subway: Line 4",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 20740,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 126,
    "name": "Barcelona Metro: Line 1",
    "region": "Barcelona",
    "country": "Spain",
    "dimensions": [
      {
        "value": 20700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 127,
    "name": "Ward Tunnel[citation needed]",
    "region": "California",
    "country": "United States",
    "dimensions": [
      {
        "value": 20610,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 128,
    "name": "Busan Metro: Line 1 (Hadan-Busan Nat'l Univ. of Education)",
    "region": "Busan",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 20600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 129,
    "name": "Xian Subway: Line 2",
    "region": "Xian",
    "country": "China",
    "dimensions": [
      {
        "value": 20500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 130,
    "name": "Daejeon Metro: Line 1",
    "region": "Daejeon",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 20470,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 131,
    "name": "Nanjing Metro Line 2",
    "region": "Nanjing",
    "country": "China",
    "dimensions": [
      {
        "value": 20380,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 132,
    "name": "Shanghai Subway: Line 6",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 20336,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 133,
    "name": "Geumjeong Tunnel",
    "region": "Busan",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 20323,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 134,
    "name": "Wushaoling Tunnel",
    "region": "Wuwei",
    "country": "China",
    "dimensions": [
      {
        "value": 20060,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 135,
    "name": "The London Connection",
    "region": "London",
    "country": "England",
    "dimensions": [
      {
        "value": 20000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 136,
    "name": "Simplon Tunnel",
    "region": "Lepontine Alps",
    "country": "Switzerland/Italy",
    "dimensions": [
      {
        "value": 19803,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 137,
    "name": "Koltsevaya Line (Circle Line)",
    "region": "Moscow Metro",
    "country": "Russia (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 19400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 138,
    "name": "Futang Hydroelectric Power Station Water Tunnel",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 19319,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 139,
    "name": "Seoul Subway: Bundang Line (Suwon station-Jukjeon station)",
    "country": "Yongin and Suwon in South Korea",
    "dimensions": [
      {
        "value": 19300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 140,
    "name": "Qamchiq Tunnel",
    "region": "Angren–Pap railway",
    "country": "Uzbekistan",
    "dimensions": [
      {
        "value": 19200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 141,
    "name": "North East line",
    "region": "MRT",
    "country": "Singapore",
    "dimensions": [
      {
        "value": 19200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 142,
    "name": "Suzhou Rail Transit: Line 2",
    "region": "Suzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 19146,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 143,
    "name": "Maskoŭskaja Line",
    "region": "Minsk Metro",
    "country": "Belarus (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 19100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 144,
    "name": "Vereina",
    "region": "Silvretta",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 19058,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 145,
    "name": "Seoul Subway: Line 4 (Ssangmun-Ichon)",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 19000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 146,
    "name": "Harbour Area Treatment Scheme (HATS) Stage 2",
    "region": "Wah Fu",
    "country": "Kennedy Town",
    "dimensions": [
      {
        "value": 18800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 147,
    "name": "Shin-Kanmon Tunnel",
    "region": "Kanmon Straits",
    "country": "Japan",
    "dimensions": [
      {
        "value": 18713,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 148,
    "name": "Shiziping Hydroelectric Power Station Water Tunnel",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 18712,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 149,
    "name": "Vaglia",
    "region": "Bologna - Firenze",
    "country": "Italy",
    "dimensions": [
      {
        "value": 18711,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 150,
    "name": "Baoxing Hydroelectric Power Station Water Tunnel",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 18676,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 151,
    "name": "Apennine Base Tunnel",
    "region": "Tuscan-Emilian Apennines",
    "country": "Italy",
    "dimensions": [
      {
        "value": 18507,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 152,
    "name": "Obolonsko–Teremkivska line",
    "region": "Kyiv Metro",
    "country": "Ukraine (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 18480,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 153,
    "name": "Qinling Tunnel I-II",
    "region": "Qin Mountains",
    "country": "China",
    "dimensions": [
      {
        "value": 18460,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 154,
    "name": "Tatev Hydroelectric Power Station Water Tunnel",
    "country": "Armenia (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 18400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 155,
    "name": "Yamate Tunnel",
    "region": "Tokyo",
    "country": "Japan",
    "dimensions": [
      {
        "value": 18200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 156,
    "name": "Shanghai Subway: Line 1",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 18110,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 157,
    "name": "Avtozavodskaya Line",
    "region": "Minsk Metro",
    "country": "Belarus (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 18100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 158,
    "name": "Zhongnanshan Tunnel",
    "region": "Shaanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 18040,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 159,
    "name": "Line 2 (Athens Metro)",
    "region": "Athens",
    "country": "Greece",
    "dimensions": [
      {
        "value": 17900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 160,
    "name": "Beijing Subway: Line 5",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 17825,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 161,
    "name": "Line 3 (Athens Metro)",
    "region": "Athens",
    "country": "Greece",
    "dimensions": [
      {
        "value": 17800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 162,
    "name": "Jingpingshan",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 17500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 163,
    "name": "Shenzhen Subway: Longgang Line",
    "region": "Shenzhen",
    "country": "China",
    "dimensions": [
      {
        "value": 17333,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 164,
    "name": "Kholodnohirsko–Zavodska Line",
    "region": "Kharkiv Metro",
    "country": "Ukraine (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 17300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 165,
    "name": "Seoul Subway: Shinbundang Line",
    "country": "Seoul and Seongnam in South Korea",
    "dimensions": [
      {
        "value": 17300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 166,
    "name": "Mavi Tünel (Blue Tunnel)",
    "region": "Konya",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 17034,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 167,
    "name": "Gotthard Road Tunnel",
    "region": "Lepontine Alps",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 16918,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 168,
    "name": "Guangzhou Metro: Line 4",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 16790,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 169,
    "name": "Barcelona Metro: Line 4",
    "region": "Barcelona",
    "country": "Spain",
    "dimensions": [
      {
        "value": 16700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 170,
    "name": "Barcelona Metro: Line 3",
    "region": "Barcelona",
    "country": "Spain",
    "dimensions": [
      {
        "value": 16600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 171,
    "name": "Seoul Subway: Line 2 (Jamsil-Sillim)",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 16700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 172,
    "name": "Barcelona Metro: Line 5",
    "region": "Barcelona",
    "country": "Spain",
    "dimensions": [
      {
        "value": 16600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 173,
    "name": "Murrumbidgee-Eucumbene Tunnel",
    "region": "NSW",
    "country": "Australia",
    "dimensions": [
      {
        "value": 16600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 174,
    "name": "Ankara Metro (Kizilay-Çayyolu)",
    "region": "Ankara",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 16590,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 175,
    "name": "İstanbul Metro (Line M2)",
    "region": "İstanbul",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 16500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 176,
    "name": "Beijing Subway: Line 9",
    "region": "Beijing",
    "country": "China",
    "dimensions": [
      {
        "value": 16500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 177,
    "name": "Jinkang Hydroelectric Power Station Water Tunnel",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 16490,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 178,
    "name": "Madrid Metro: Line 8",
    "region": "Madrid",
    "country": "Spain",
    "dimensions": [
      {
        "value": 16467,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 179,
    "name": "Guangzhou Metro: Line 1",
    "region": "Guangzhou",
    "country": "China",
    "dimensions": [
      {
        "value": 16449,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 180,
    "name": "Madrid Metro: Line 3",
    "region": "Madrid",
    "country": "Spain",
    "dimensions": [
      {
        "value": 16424,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 181,
    "name": "Rokkō Tunnel",
    "region": "Mount Rokkō",
    "country": "Japan",
    "dimensions": [
      {
        "value": 16250,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 182,
    "name": "Solan Tunnel",
    "region": "Taebaek",
    "country": "Gangwon-do",
    "dimensions": [
      {
        "value": 16240,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 183,
    "name": "Seoul Subway: Line 7 (Suraksan-Konkuk University)",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 16000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 184,
    "name": "Münsterertunnel",
    "region": "Tyrol",
    "country": "Austria",
    "dimensions": [
      {
        "value": 15990,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 185,
    "name": "İstanbul Metro (Line M3)",
    "region": "İstanbul",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 15900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 186,
    "name": "Terfnertunnel",
    "region": "Tyrol",
    "country": "Austria",
    "dimensions": [
      {
        "value": 15840,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 187,
    "name": "Henderson Tunnel",
    "region": "Front Range",
    "country": "United States",
    "dimensions": [
      {
        "value": 15800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 188,
    "name": "Pandaoling Tunnel, Datong River Diversion to Shaanxi Project",
    "region": "Shaanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 15723,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 189,
    "name": "Copenhagen Metro: City Circle Line",
    "country": "Denmark",
    "dimensions": [
      {
        "value": 15500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 190,
    "name": "Budapest Metro: Line 3",
    "country": "Hungary",
    "dimensions": [
      {
        "value": 15500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 191,
    "name": "Furka Base",
    "region": "Urner Alps",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 15442,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 192,
    "name": "Ceneri Base Tunnel",
    "region": "Lepontine Alps",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 15400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 193,
    "name": "Seoul Subway: Line 3 (Gupabal-Geumho)",
    "region": "Seoul",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 15400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 194,
    "name": "Tianjin Subway: Line 1",
    "region": "Tianjin",
    "country": "China",
    "dimensions": [
      {
        "value": 15378,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 195,
    "name": "Haruna",
    "region": "Gunma Prefecture",
    "country": "Japan",
    "dimensions": [
      {
        "value": 15350,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 196,
    "name": "Severomuysky Tunnel",
    "region": "Severomuysky Range",
    "country": "Russia (construction started at the time of  Soviet Union)",
    "dimensions": [
      {
        "value": 15343,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 197,
    "name": "Deer Island Outfall Tunnel",
    "region": "Deer Island",
    "country": "Boston",
    "dimensions": [
      {
        "value": 15290,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 198,
    "name": "Firenzuola",
    "region": "Bologna - Firenze",
    "country": "Italy",
    "dimensions": [
      {
        "value": 15285,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 199,
    "name": "Inguri Hydroelectric Power Station Water Tunnel",
    "country": "Georgia (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 15279,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 200,
    "name": "Shanghai Subway: Line 2",
    "region": "Shanghai",
    "country": "China",
    "dimensions": [
      {
        "value": 15274,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 201,
    "name": "Jinquidi Tunnel, Niulan River Diversion to Dianchi",
    "region": "Yunnan",
    "country": "China",
    "dimensions": [
      {
        "value": 15257,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 202,
    "name": "Baotan Hydroelectric Power Station Water Tunnel",
    "region": "Guangxi",
    "country": "China",
    "dimensions": [
      {
        "value": 15200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 203,
    "name": "Gorigamine Tunnel",
    "region": "Takasaki - Nagano",
    "country": "Japan",
    "dimensions": [
      {
        "value": 15175,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 204,
    "name": "Xuecheng Hydroelectric Power Station Water Tunnel",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 15174,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 205,
    "name": "Monte Santomarco",
    "region": "Paola - Cosenza",
    "country": "Italy",
    "dimensions": [
      {
        "value": 15040,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 206,
    "name": "Gotthard Rail Tunnel",
    "region": "Lepontine Alps",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 15003,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 207,
    "name": "Gwangju Metro: Line 1 (Sotae station-Dosan station)",
    "region": "Gwangju",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 15000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 208,
    "name": "Nakayama Tunnel",
    "region": "Gunma Prefecture",
    "country": "Japan",
    "dimensions": [
      {
        "value": 14857,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 209,
    "name": "Dangara Irrigation Tunnel",
    "region": "Nurek",
    "country": "Tajikistan (at the time of construction  Soviet Union)",
    "dimensions": [
      {
        "value": 14800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 210,
    "name": "Cuajone-El Sargento",
    "region": "Ilo-Toquepala / Cuajone Industrial Railroad - Southern Peru Copper Corporation",
    "country": "Peru",
    "dimensions": [
      {
        "value": 14724,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 211,
    "name": "Mount Macdonald Tunnel",
    "region": "Rogers Pass",
    "country": "Glacier National Park",
    "dimensions": [
      {
        "value": 14723,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 212,
    "name": "Lötschberg Tunnel",
    "region": "Alps",
    "country": "Switzerland",
    "dimensions": [
      {
        "value": 14612,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 213,
    "name": "Caoe River Diversion Project",
    "region": "Zhejiang",
    "country": "China",
    "dimensions": [
      {
        "value": 14600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 214,
    "name": "Romerike Tunnel",
    "region": "Oslo - Lillestrøm",
    "country": "Norway",
    "dimensions": [
      {
        "value": 14580,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 215,
    "name": "Snowy-Geehi Tunnel",
    "region": "NSW",
    "country": "Australia",
    "dimensions": [
      {
        "value": 14500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 216,
    "name": "Seoul Subway: Line 4 (Isu-Beomgye)",
    "region": "Seoul",
    "country": "Gwacheon and Anyang in South Korea",
    "dimensions": [
      {
        "value": 14500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 217,
    "name": "Jisha Hydroelectric Power Station Water Tunnel",
    "region": "Yunnan",
    "country": "China",
    "dimensions": [
      {
        "value": 14467,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 218,
    "name": "Ryfylke Tunnel",
    "region": "Stavanger-Strand",
    "country": "Norway",
    "dimensions": [
      {
        "value": 14400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 219,
    "name": "#6 Tunnel, Yellow River Diversion to Shanxi South Line",
    "region": "Shanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 14400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 220,
    "name": "Ala Mountain Pass Water Supply Project",
    "region": "Xinjiang",
    "country": "China",
    "dimensions": [
      {
        "value": 14346,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 221,
    "name": "Mount Ovit Tunnel",
    "region": "Erzurum Province-Rize Province",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 14346,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 222,
    "name": "Stockholm Metro: Tunnelbana 3 (Blue Line) Kungsträdgården-Hjulsta",
    "region": "Stockholm",
    "country": "Sweden",
    "dimensions": [
      {
        "value": 14300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 223,
    "name": "Dayaoshan Tunnel",
    "region": "Nanling Mountains",
    "country": "China",
    "dimensions": [
      {
        "value": 14294,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 224,
    "name": "Zaramag Hydroelectric Power Station Water Tunnel",
    "region": "Ardon River",
    "country": "North Ossetia",
    "dimensions": [
      {
        "value": 14226,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 225,
    "name": "Tooma-Tumut Tunnel",
    "region": "NSW",
    "country": "Australia",
    "dimensions": [
      {
        "value": 14200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 226,
    "name": "Metro Lisbon - Blue (Seagull) Line",
    "region": "Lisbon",
    "country": "Portugal",
    "dimensions": [
      {
        "value": 14000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 227,
    "name": "MTR - Island line (West of Heng Fa Chuen)",
    "region": "Victoria City",
    "country": "Hong Kong",
    "dimensions": [
      {
        "value": 14000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 228,
    "name": "Arlberg Road Tunnel",
    "region": "Arlberg",
    "country": "Austria",
    "dimensions": [
      {
        "value": 13972,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 229,
    "name": "Shanggongshan Tunnel, Zhangjiuhe Water Supply Project",
    "region": "Yunnan",
    "country": "China",
    "dimensions": [
      {
        "value": 13941,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 230,
    "name": "Dayingshan #4 Hydroelectric Power Station Water Tunnel",
    "region": "Yunnan",
    "country": "China",
    "dimensions": [
      {
        "value": 13932,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 231,
    "name": "Hokuriku Tunnel",
    "region": "Fukui Prefecture",
    "country": "Japan",
    "dimensions": [
      {
        "value": 13870,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 232,
    "name": "Xiapu Tunnel",
    "region": "Fujian",
    "country": "China",
    "dimensions": [
      {
        "value": 13838,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 233,
    "name": "Yesanguan Tunnel",
    "region": "Hubei",
    "country": "China",
    "dimensions": [
      {
        "value": 13838,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 234,
    "name": "Centrala Hidroelectrică Lotru-Ciunget",
    "region": "Ciungetu",
    "country": "Romania",
    "dimensions": [
      {
        "value": 13719,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 235,
    "name": "Fréjus (Mont Cenis)",
    "region": "Alps",
    "country": "France/Italy (construction started in the Kingdom of Sardinia)",
    "dimensions": [
      {
        "value": 13657,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 236,
    "name": "Xishan Tunnel",
    "region": "Shanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 13654,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 237,
    "name": "North Tianshan Tunnel",
    "region": "Xinjiang",
    "country": "China",
    "dimensions": [
      {
        "value": 13610,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 238,
    "name": "Marmaray",
    "region": "Istanbul",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 13600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 239,
    "name": "Savio Rail Tunnel",
    "region": "Helsinki - Kerava",
    "country": "Finland",
    "dimensions": [
      {
        "value": 13575,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 240,
    "name": "Busan Metro: Line 3 (Suyeong-Deokcheon)",
    "region": "Busan",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 13500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 241,
    "name": "Shin Shimizu Tunnel",
    "region": "Mount Tanigawa",
    "country": "Japan",
    "dimensions": [
      {
        "value": 13500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 242,
    "name": "Mount Erlang Tunnel",
    "region": "Sichuan",
    "country": "China",
    "dimensions": [
      {
        "value": 13433,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 243,
    "name": "Hex River Tunnel",
    "region": "Hex River Pass",
    "country": "South Africa",
    "dimensions": [
      {
        "value": 13400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 244,
    "name": "Wonhyo Tunnel",
    "region": "Ulsan",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 13270,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 245,
    "name": "Dabieshan Tunnel",
    "region": "Hubei",
    "country": "China",
    "dimensions": [
      {
        "value": 13256,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 246,
    "name": "Schlern Tunnel",
    "region": "South Tyrol",
    "country": "Italy",
    "dimensions": [
      {
        "value": 13159,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 247,
    "name": "Caponero-Capoverde",
    "region": "Genova - Ventimiglia",
    "country": "Italy",
    "dimensions": [
      {
        "value": 13135,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 248,
    "name": "Hongtiguan Tunnel",
    "region": "Shanxi",
    "country": "China",
    "dimensions": [
      {
        "value": 13122,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 249,
    "name": "Barcelona Metro Line 2",
    "region": "Barcelona",
    "country": "Spain",
    "dimensions": [
      {
        "value": 13100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 250,
    "name": "Aki Tunnel",
    "region": "San'yō Shinkansen",
    "country": "Japan",
    "dimensions": [
      {
        "value": 13030,
        "unit": "meters",
        "type": "length"
      }
    ]
  }
]