import Qty from 'js-quantities';
import heightsBuildings from '../data/heights-buildings.json';
import heightsMountains from '../data/heights-mountains.json';
import lengthsBridges from '../data/lengths-bridges.json';
import lengthsTunnels from '../data/lengths-tunnels.json';
import speedsCoastersSteel from '../data/speeds-coasters-steel.json';
import speedsCoastersWood from '../data/speeds-coasters-wood.json';
import weightsSpacecraft from '../data/weights-spacecraft.json';

let kindSources = {
  length: [
    lengthsBridges,
    lengthsTunnels,
    heightsBuildings,
    heightsMountains,
  ],
  speed: [
    speedsCoastersSteel,
    speedsCoastersWood,
  ],
  mass: [
    weightsSpacecraft,
  ]
};

const exampleEl = document.querySelector('#example');
const unitsEl = document.querySelector('#units');
const dimensionInputEl = document.querySelector('#dimension-input');
const outputStartEl = document.querySelector('#output-start');
const outputKindEl = document.querySelector('#output-kind');
const outputComparativeEl = document.querySelector('#output-comparative');
const outputDifferenceEl = document.querySelector('#output-difference');

const formatKind = function (distance, describe) {
  let kind = distance.kind();
  let scalar = distance.scalar;

  switch (kind) {
    case 'length':
      return describe ? (scalar > 0 ? 'shorter' : 'longer') : (scalar > 0 ? 'short' : 'long');
    case 'speed':
      return describe ? (scalar > 0 ? 'slower' : 'faster') : (scalar > 0 ? 'slow' : 'fast');
    case 'mass':
      return describe ? (scalar > 0 ? 'lighter' : 'heavier') : (scalar > 0 ? 'light' : 'heavy');
    default:
      return 'Unknown';
  }
}

const formatDistance = function (distance) {
  let kind = distance.kind();
  let format = formatKind(distance);

  if(distance.value === 0) {
    return {
      short: `exactly as  ${format}`,
      full: `(exact match)`,
    };
  } else if (distance.value < 0.1) {
    return {
      short: `almost as  ${format}`,
      full: `Give or take ${distance}`,
    };
  } else if(distance.scalar < 0) {
    return {
      short: `more ${format}`,
      full: `which is ${distance} ${formatKind(distance, true)}`,
    };
  } else {
    return {
      short: `not nearly as  ${format}`,
      full: `which is ${distance} ${formatKind(distance, true)}`,
    };
  }
}

dimensionInputEl.addEventListener('input', function () {
  let quantity = Qty.parse(dimensionInputEl.value);
  let kind = quantity?.kind();

  if (!kind || kind == 'unitless') return;
  
  let sources = kindSources[kind];

  if (!sources) {
    throw new Error(`No sources for ${kind}`);
  }

  let source = sources[Math.floor(Math.random() * sources.length)];

  // Go through the source and find which items' dimensions are closest to the quantity
  let closest;
  
  source.forEach((item) => {
    let dimension = item.dimensions[0];
    let itemQuantity = Qty(`${dimension.value} ${dimension.unit}`);
    let distance = itemQuantity.sub(quantity);

    if (!closest || distance < closest.distance) {
      closest = {
        item: item,
        distance: distance,
      };
    }
  });

  let difference = formatDistance(closest.distance);

  outputStartEl.innerText = quantity.toString();
  outputKindEl.innerText = difference.short;
  outputComparativeEl.innerText = `the ${closest.item.name}`;
  outputDifferenceEl.innerText = difference.full;
});