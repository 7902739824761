[
  {
    "rank": "1.",
    "name": "Mount Everest",
    "region": "Himalayas",
    "country": "Nepal\n China",
    "dimensions": [
      {
        "value": 8848.86,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "2.",
    "name": "Aconcagua",
    "region": "Andes",
    "country": "Argentina",
    "dimensions": [
      {
        "value": 6960.8,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "3.",
    "name": "Denali*",
    "region": "Alaska Range",
    "country": "United States",
    "dimensions": [
      {
        "value": 6144,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "4.",
    "name": "Mount Kilimanjaro*",
    "region": "Eastern Rift mountains",
    "country": "Tanzania",
    "dimensions": [
      {
        "value": 5885,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "5.",
    "name": "Pico Cristóbal Colón",
    "region": "Sierra Nevada de Santa Marta",
    "country": "Colombia",
    "dimensions": [
      {
        "value": 5509,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "6.",
    "name": "Mount Logan",
    "region": "Saint Elias Mountains",
    "country": "Canada",
    "dimensions": [
      {
        "value": 5250,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "7.",
    "name": "Pico de Orizaba",
    "region": "Trans-Mexican Volcanic Belt",
    "country": "Mexico",
    "dimensions": [
      {
        "value": 4922,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "8.",
    "name": "Vinson Massif",
    "region": "Sentinel Range",
    "country": "Antarctica",
    "dimensions": [
      {
        "value": 4892,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "9.",
    "name": "Puncak Jaya",
    "region": "Sudirman Range, New Guinea",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 4884,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "10.",
    "name": "Mount Elbrus",
    "region": "Caucasus Mountains",
    "country": "Russia",
    "dimensions": [
      {
        "value": 4741,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "11.",
    "name": "Mont Blanc",
    "region": "Alps",
    "country": "Italy\n France",
    "dimensions": [
      {
        "value": 4695,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "12.",
    "name": "Mount Damavand",
    "region": "Alborz",
    "country": "Iran",
    "dimensions": [
      {
        "value": 4667,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "13.",
    "name": "Klyuchevskaya Sopka",
    "region": "Kamchatka",
    "country": "Russia",
    "dimensions": [
      {
        "value": 4649,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "14.",
    "name": "Nanga Parbat",
    "region": "Himalayas",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 4608,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "15.",
    "name": "Mauna Kea",
    "region": "Hawaii, Hawaiian Islands",
    "country": "United States",
    "dimensions": [
      {
        "value": 4205,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "16.",
    "name": "Jengish Chokusu",
    "region": "Kakshaal Too",
    "country": "China\n Kyrgyzstan",
    "dimensions": [
      {
        "value": 4148,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "17.",
    "name": "Bogda Peak",
    "region": "Bogda Shan",
    "country": "China",
    "dimensions": [
      {
        "value": 4122,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "18.",
    "name": "Chimborazo",
    "region": "Cordillera Occidental",
    "country": "Ecuador",
    "dimensions": [
      {
        "value": 4118,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "19.",
    "name": "Namcha Barwa",
    "region": "Himalayas",
    "country": "China",
    "dimensions": [
      {
        "value": 4106,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "20.",
    "name": "Mount Kinabalu",
    "region": "Crocker Range, Borneo",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 4095,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "21.",
    "name": "Mount Rainier",
    "region": "Cascade Range",
    "country": "United States",
    "dimensions": [
      {
        "value": 4023,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "22.",
    "name": "K2",
    "region": "Karakoram",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 4017,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "23.",
    "name": "Ras Dashen",
    "region": "Semien Mountains",
    "country": "Ethiopia",
    "dimensions": [
      {
        "value": 3997,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "24.",
    "name": "Volcán Tajumulco",
    "region": "Sierra Madre de Chiapas",
    "country": "Guatemala",
    "dimensions": [
      {
        "value": 3980,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "25.",
    "name": "Pico Bolívar",
    "region": "Sierra Nevada de Mérida",
    "country": "Venezuela",
    "dimensions": [
      {
        "value": 3957,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "26.",
    "name": "Mount Fairweather",
    "region": "Saint Elias Mountains",
    "country": "Canada\n United States",
    "dimensions": [
      {
        "value": 3955,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "27.",
    "name": "Yushan",
    "region": "Yushan Range",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 3952,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "28.",
    "name": "Mount Stanley",
    "region": "Rwenzori Mountains",
    "country": "DR Congo\n Uganda",
    "dimensions": [
      {
        "value": 3951,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "29.",
    "name": "Kanchenjunga",
    "region": "Himalayas",
    "country": "Nepal\n India",
    "dimensions": [
      {
        "value": 3922,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "30.",
    "name": "Tirich Mir",
    "region": "Hindu Kush",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 3908,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "31.",
    "name": "Mount Cameroon",
    "region": "Cameroon line",
    "country": "Cameroon",
    "dimensions": [
      {
        "value": 3901,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "32.",
    "name": "Mount Kenya",
    "region": "Eastern Rift mountains",
    "country": "Kenya",
    "dimensions": [
      {
        "value": 3825,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "33.",
    "name": "Mount Kerinci",
    "region": "Barisan Mountains, Sumatra",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3805,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "34.",
    "name": "Mount Erebus",
    "region": "Ross Island",
    "country": "Antarctica",
    "dimensions": [
      {
        "value": 3794,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "35.",
    "name": "Mount Fuji",
    "region": "Honshu",
    "country": "Japan",
    "dimensions": [
      {
        "value": 3776,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "36.",
    "name": "Toubkal",
    "region": "Atlas Mountains",
    "country": "Morocco",
    "dimensions": [
      {
        "value": 3757,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "37.",
    "name": "Cerro Chirripó",
    "region": "Cordillera de Talamanca",
    "country": "Costa Rica",
    "dimensions": [
      {
        "value": 3727,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "38.",
    "name": "Mount Rinjani",
    "region": "Lombok, Lesser Sunda Islands",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3726,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "39.",
    "name": "Aoraki/Mount Cook",
    "region": "Southern Alps",
    "country": "New Zealand",
    "dimensions": [
      {
        "value": 3724,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "40.",
    "name": "Teide",
    "region": "Tenerife",
    "country": "Spain",
    "dimensions": [
      {
        "value": 3715,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "41.",
    "name": "Mount Boising",
    "region": "Finisterre Range",
    "country": "Papua New Guinea",
    "dimensions": [
      {
        "value": 3709,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "42.",
    "name": "Monte San Valentin",
    "region": "Andes",
    "country": "Chile",
    "dimensions": [
      {
        "value": 3696,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "43.",
    "name": "Gunnbjørn Fjeld",
    "region": "Watkins Range",
    "country": "Greenland",
    "dimensions": [
      {
        "value": 3694,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "44.",
    "name": "Ojos del Salado",
    "region": "Andes",
    "country": "Argentina\n Chile",
    "dimensions": [
      {
        "value": 3688,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "45.",
    "name": "Semeru",
    "region": "Java",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3676,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "46.",
    "name": "Ritacuba Blanco",
    "region": "Cordillera Oriental",
    "country": "Colombia",
    "dimensions": [
      {
        "value": 3645,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "47.",
    "name": "Mount Gongga",
    "region": "Daxue Mountains",
    "country": "China",
    "dimensions": [
      {
        "value": 3642,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "48.",
    "name": "Mount Ararat",
    "region": "Armenian Highlands",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 3611,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "49.",
    "name": "Kongur Tagh",
    "region": "Kongur Shan",
    "country": "China",
    "dimensions": [
      {
        "value": 3585,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "50.",
    "name": "Mount Blackburn",
    "region": "Wrangell Mountains",
    "country": "United States",
    "dimensions": [
      {
        "value": 3535,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "51.",
    "name": "Mount Hayes",
    "region": "Alaska Range",
    "country": "United States",
    "dimensions": [
      {
        "value": 3501,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "52.",
    "name": "Mount Latimojong",
    "region": "Sulawesi",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3478,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "53.",
    "name": "Mount Saint Elias",
    "region": "Saint Elias Mountains",
    "country": "Canada\n United States",
    "dimensions": [
      {
        "value": 3448,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "54.",
    "name": "Ismoil Somoni Peak",
    "region": "Pamir Mountains",
    "country": "Tajikistan",
    "dimensions": [
      {
        "value": 3402,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "55.",
    "name": "Dhaulagiri",
    "region": "Himalayas",
    "country": "Nepal",
    "dimensions": [
      {
        "value": 3357,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "56.",
    "name": "Mercedario",
    "region": "Cordillera de la Ramada",
    "country": "Argentina",
    "dimensions": [
      {
        "value": 3353,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "57.",
    "name": "Lautaro",
    "region": "Andes",
    "country": "Chile",
    "dimensions": [
      {
        "value": 3345,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "58.",
    "name": "Belukha Mountain",
    "region": "Altai Mountains",
    "country": "Kazakhstan\n Russia",
    "dimensions": [
      {
        "value": 3343,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "59.",
    "name": "Mount Etna",
    "region": "Sicily",
    "country": "Italy",
    "dimensions": [
      {
        "value": 3329,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "60.",
    "name": "Monte San Lorenzo",
    "region": "Andes",
    "country": "Argentina\n Chile",
    "dimensions": [
      {
        "value": 3319,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "61.",
    "name": "Mount Karisimbi",
    "region": "Virunga Mountains",
    "country": "DR Congo\n Rwanda",
    "dimensions": [
      {
        "value": 3312,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "62.",
    "name": "Jabal an Nabi Shu'ayb",
    "region": "Sarawat Mountains",
    "country": "Yemen",
    "dimensions": [
      {
        "value": 3311,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "63.",
    "name": "Mount Waddington",
    "region": "Coast Mountains",
    "country": "Canada",
    "dimensions": [
      {
        "value": 3289,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "64.",
    "name": "Mulhacén",
    "region": "Sierra Nevada",
    "country": "Spain",
    "dimensions": [
      {
        "value": 3285,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "65.",
    "name": "Mount Slamet",
    "region": "Java",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3284,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "66.",
    "name": "Sabalan",
    "region": "Alborz",
    "country": "Iran",
    "dimensions": [
      {
        "value": 3283,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "67.",
    "name": "Mount Marcus Baker",
    "region": "Chugach Mountains",
    "country": "United States",
    "dimensions": [
      {
        "value": 3269,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "68.",
    "name": "Sauyr Zhotasy",
    "region": "Saur Mountains",
    "country": "China\n Kazakhstan",
    "dimensions": [
      {
        "value": 3252,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "69.",
    "name": "Cerro del Bolsón",
    "region": "Andes",
    "country": "Argentina",
    "dimensions": [
      {
        "value": 3252,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "70.",
    "name": "Tomort",
    "region": "Tian Shan",
    "country": "China",
    "dimensions": [
      {
        "value": 3243,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "71.",
    "name": "Jade Dragon Snow Mountain",
    "region": "Yun Range",
    "country": "China",
    "dimensions": [
      {
        "value": 3202,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "72.",
    "name": "Mount Meru",
    "region": "Eastern Rift mountains",
    "country": "Tanzania",
    "dimensions": [
      {
        "value": 3170,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "73.",
    "name": "Shiveluch",
    "region": "Kamchatka",
    "country": "Russia",
    "dimensions": [
      {
        "value": 3168,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "74.",
    "name": "Nanda Devi",
    "region": "Himalayas",
    "country": "India",
    "dimensions": [
      {
        "value": 3139,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "75.",
    "name": "Ichinsky",
    "region": "Sredinny Range",
    "country": "Russia",
    "dimensions": [
      {
        "value": 3125,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "76.",
    "name": "Mount Lawu",
    "region": "Java",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3118,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "77.",
    "name": "Batura Sar",
    "region": "Karakoram",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 3118,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "78.",
    "name": "Mount Siple",
    "region": "Siple Island",
    "country": "Antarctica",
    "dimensions": [
      {
        "value": 3110,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "79.",
    "name": "Pico Duarte",
    "region": "Cordillera Central, Hispaniola",
    "country": "Dominican Republic",
    "dimensions": [
      {
        "value": 3098,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "80.",
    "name": "Manaslu",
    "region": "Himalayas",
    "country": "Nepal",
    "dimensions": [
      {
        "value": 3092,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "81.",
    "name": "Mount Whitney",
    "region": "Sierra Nevada",
    "country": "United States",
    "dimensions": [
      {
        "value": 3073,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "82.",
    "name": "Piton des Neiges",
    "region": "Réunion",
    "country": "France",
    "dimensions": [
      {
        "value": 3069,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "83.",
    "name": "Raung",
    "region": "Java",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3069,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "84.",
    "name": "Xuelian Feng",
    "region": "Tian Shan",
    "country": "China",
    "dimensions": [
      {
        "value": 3068,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "85.",
    "name": "Haleakalā",
    "region": "Maui, Hawaiian Islands",
    "country": "United States",
    "dimensions": [
      {
        "value": 3055,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "86.",
    "name": "Mount Lucania",
    "region": "Saint Elias Mountains",
    "country": "Canada",
    "dimensions": [
      {
        "value": 3046,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "87.",
    "name": "Mount Agung",
    "region": "Bali",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3031,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "88.",
    "name": "Mount Binaiya",
    "region": "Seram",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 3027,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "89.",
    "name": "Popocatepetl",
    "region": "Trans-Mexican Volcanic Belt",
    "country": "Mexico",
    "dimensions": [
      {
        "value": 3020,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "90.",
    "name": "Pico Basilé",
    "region": "Bioko, Cameroon Line",
    "country": "Equatorial Guinea",
    "dimensions": [
      {
        "value": 3011,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "91.",
    "name": "Koryaksky",
    "region": "Sredinny Range",
    "country": "Russia",
    "dimensions": [
      {
        "value": 2999,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "92.",
    "name": "Gangkhar Puensum",
    "region": "Himalayas",
    "country": "China\n Bhutan",
    "dimensions": [
      {
        "value": 2995,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "93.",
    "name": "Mount Stephenson",
    "region": "Douglas Range, Alexander Island",
    "country": "Antarctica",
    "dimensions": [
      {
        "value": 2987,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "94.",
    "name": "Annapurna",
    "region": "Himalayas",
    "country": "Nepal",
    "dimensions": [
      {
        "value": 2984,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "95.",
    "name": "Pik Talgar",
    "region": "Trans-Ili Alatau",
    "country": "Kazakhstan",
    "dimensions": [
      {
        "value": 2982,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "96.",
    "name": "Mount Shasta",
    "region": "Cascade Range",
    "country": "United States",
    "dimensions": [
      {
        "value": 2977,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "97.",
    "name": "Mount Wilhelm",
    "region": "Bismarck Range",
    "country": "Papua New Guinea",
    "dimensions": [
      {
        "value": 2969,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "98.",
    "name": "Pico do Ramelau",
    "region": "Timor",
    "country": "Timor-Leste",
    "dimensions": [
      {
        "value": 2963,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "99.",
    "name": "Mount Apo",
    "region": "Apo–Talomo, Mindanao",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 2954,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "100.",
    "name": "Gyala Peri",
    "region": "Nyenchen Tanglha Mountains",
    "country": "China",
    "dimensions": [
      {
        "value": 2942,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "101.",
    "name": "Mount Leuser",
    "region": "Sumatra",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 2941,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "102.",
    "name": "Mount Paget",
    "region": "Allardyce Range",
    "country": "South Georgia",
    "dimensions": [
      {
        "value": 2934,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "103.",
    "name": "Chakragil",
    "region": "Kunlun Mountains",
    "country": "China",
    "dimensions": [
      {
        "value": 2934,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "104.",
    "name": "Emi Koussi",
    "region": "Tibesti Mountains",
    "country": "Chad",
    "dimensions": [
      {
        "value": 2934,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "105.",
    "name": "Monarch Mountain",
    "region": "Coast Mountains",
    "country": "Canada",
    "dimensions": [
      {
        "value": 2930,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "106.",
    "name": "Mount Suckling",
    "region": "Owen Stanley Range",
    "country": "Papua New Guinea",
    "dimensions": [
      {
        "value": 2925,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "107.",
    "name": "Mount Pulag",
    "region": "Cordillera Central, Luzon",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 2922,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "108.",
    "name": "El Mela",
    "region": "Andes",
    "country": "Argentina",
    "dimensions": [
      {
        "value": 2907,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "109.",
    "name": "Taftan",
    "region": "Baluchestan Mountains",
    "country": "Iran",
    "dimensions": [
      {
        "value": 2901,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "110.",
    "name": "Jabal Shams",
    "region": "Al Hajar Mountains",
    "country": "Oman",
    "dimensions": [
      {
        "value": 2899,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "111.",
    "name": "Shishapangma",
    "region": "Himalayas",
    "country": "China",
    "dimensions": [
      {
        "value": 2897,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "112.",
    "name": "Pico da Neblina",
    "region": "Cerro de la Neblina",
    "country": "Brazil",
    "dimensions": [
      {
        "value": 2886,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "113.",
    "name": "Saramati",
    "region": "Patkai",
    "country": "India\n Myanmar",
    "dimensions": [
      {
        "value": 2885,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "114.",
    "name": "Maromokotro",
    "region": "Madagascar",
    "country": "Madagascar",
    "dimensions": [
      {
        "value": 2876,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "115.",
    "name": "Mount Shishaldin",
    "region": "Aleutian Range",
    "country": "United States",
    "dimensions": [
      {
        "value": 2869,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "116.",
    "name": "Moncong Lompotabang",
    "region": "Sulawesi",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 2857,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "117.",
    "name": "Buni Zom",
    "region": "Hindu Raj",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 2845,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "118.",
    "name": "Kuh-e Bandaka",
    "region": "Hindu Kush",
    "country": "Afghanistan",
    "dimensions": [
      {
        "value": 2834,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "119.",
    "name": "Mount Robson",
    "region": "Canadian Rockies",
    "country": "Canada",
    "dimensions": [
      {
        "value": 2829,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "120.",
    "name": "Mount Fogo",
    "region": "Fogo",
    "country": "Cape Verde",
    "dimensions": [
      {
        "value": 2829,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "121.",
    "name": "Kamet",
    "region": "Himalayas",
    "country": "India",
    "dimensions": [
      {
        "value": 2825,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "122.",
    "name": "Rakaposhi",
    "region": "Karakoram",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 2818,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "123.",
    "name": "Aneto",
    "region": "Pyrenees",
    "country": "Spain",
    "dimensions": [
      {
        "value": 2812,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "124.",
    "name": "Arjuno-Welirang",
    "region": "Java",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 2812,
        "unit": "meters",
        "type": "height"
      }
    ]
  },
  {
    "rank": "125.",
    "name": "Jiuding Shan",
    "region": "Daxue Mountains",
    "country": "China",
    "dimensions": [
      {
        "value": 2808,
        "unit": "meters",
        "type": "height"
      }
    ]
  }
]