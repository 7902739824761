[
  {
    "rank": 1,
    "name": "Danyang–Kunshan Grand Bridge\nBeijing–Shanghai High-Speed Railway\nGuinness: Longest bridge (any type), 2011",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 164800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 2,
    "name": "Changhua–Kaohsiung Viaduct\nTaiwan High Speed Rail",
    "notes": "High-speed rail",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 157317,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 3,
    "name": "Kita-Yaita Viaduct Tohoku Shinkansen",
    "notes": "High-speed rail",
    "country": "Japan",
    "dimensions": [
      {
        "value": 114424,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 4,
    "name": "Tianjin Grand Bridge\nBeijing–Shanghai High-Speed Railway\nGuinness: Second longest bridge (any type), 2011",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 113700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 5,
    "name": "Cangde Grand Bridge\nBeijing–Shanghai High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 115900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 6,
    "name": "Weinan Weihe Grand Bridge\nZhengzhou–Xi'an High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 79732,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 7,
    "name": "Bang Na Expressway\nGuinness: Longest Road Bridge, 2000",
    "notes": "Expressway",
    "country": "Thailand",
    "dimensions": [
      {
        "value": 54000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 8,
    "name": "Beijing Grand Bridge\nBeijing–Shanghai High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 48153,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 9,
    "name": "Metro Manila Skyway System",
    "notes": "Expressway",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 39200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 10,
    "name": "Lake Pontchartrain Causeway\n(across Lake Pontchartrain in Louisiana)\nGuinness: Longest bridge over water (continuous), 1969",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 38442,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 11,
    "name": "Line 1, Wuhan Metro Bridge\nWuhan Metro",
    "notes": "Metro",
    "country": "China",
    "dimensions": [
      {
        "value": 37788,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 12,
    "name": "Manchac Swamp Bridge\n(I-55/US 51 across Manchac Swamp in Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 36710,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 13,
    "name": "Sheikh Mohammed bin Zayed Skyway",
    "notes": "Highway",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 36400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 14,
    "name": "Sheikh Jaber Al-Ahmad Al-Sabah Causeway Main Link",
    "notes": "Highway",
    "country": "Kuwait",
    "dimensions": [
      {
        "value": 36140,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 15,
    "name": "Yangcun Bridge\nBeijing-Tianjin Intercity Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 35812,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 16,
    "name": "Hangzhou Bay Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 35673,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 17,
    "name": "Runyang Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 35660,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 18,
    "name": "Line 1 - Lima Metro",
    "notes": "Metro",
    "country": "Peru",
    "dimensions": [
      {
        "value": 34600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 19,
    "name": "Donghai Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 32500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 20,
    "name": "Temburong Bridge",
    "notes": "Highway",
    "country": "Brunei Darussalam",
    "dimensions": [
      {
        "value": 30000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 21,
    "name": "Shanghai Maglev line",
    "notes": "Maglev",
    "country": "China",
    "dimensions": [
      {
        "value": 29908,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 22,
    "name": "Dwarka Sector 8 Metro Station-Rama Krishna Ashram Marg Metro Station",
    "notes": "Metro",
    "country": "India",
    "dimensions": [
      {
        "value": 29808,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 23,
    "name": "Atchafalaya Basin Bridge\n(I-10 across Atchafalaya Basin in Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 29290,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 24,
    "name": "Yanshi Bridge\nZhengzhou–Xi'an High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 28543,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 25,
    "name": "Jiaozhou Bay Bridge",
    "notes": "Road",
    "country": "China",
    "dimensions": [
      {
        "value": 26707,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 26,
    "name": "Jintang Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 26540,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 27,
    "name": "Jinbin Light Rail No. 1 Bridge[citation needed](Guanghualu – Babaocun)\nTianjin Binhai Mass Transit",
    "notes": "Metro",
    "country": "China",
    "dimensions": [
      {
        "value": 25800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 28,
    "name": "East West MRT line (Redhill - Tuas Link)\nMass Rapid Transit (Singapore)",
    "notes": "Metro",
    "country": "Singapore",
    "dimensions": [
      {
        "value": 25700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 29,
    "name": "Suvarnabhumi Airport Link",
    "notes": "Rail",
    "country": "Thailand",
    "dimensions": [
      {
        "value": 24500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 30,
    "name": "Chesapeake Bay Bridge-Tunnel\n(US 13 across Chesapeake Bay in Virginia)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 24140,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 31,
    "name": "Sultan Abdul Halim Muadzam Shah Bridge\nPenang Second Bridge",
    "notes": "Highway",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 24000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 32,
    "name": "MRT Sungai Buloh-Kajang Line Northern Elevated Section",
    "notes": "Metro",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 23100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 33,
    "name": "Bridge section of Hong Kong–Zhuhai–Macau Bridge\nGuinness: Longest bridge over water (aggregate), October 2018",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 22900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 34,
    "name": "Liangshui River Bridge\nBeijing-Tianjin Intercity Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 21563,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 35,
    "name": "Incheon Bridge",
    "notes": "Road",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 21380,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 36,
    "name": "Yongding New River Bridge\nBeijing-Tianjin Intercity Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 21133,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 37,
    "name": "6th October Bridge",
    "notes": "Road",
    "country": "Egypt",
    "dimensions": [
      {
        "value": 20500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 38,
    "name": "C215 Viaduct\nTaiwan High Speed Rail",
    "notes": "High-speed rail",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 20000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 39,
    "name": "Manila LRT Line 1",
    "notes": "Metro",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 19650,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 40,
    "name": "Hami Grade Separation Bridge\nLanzhou–Urumqi High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 19300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 41,
    "name": "MRT Sungai Buloh-Kajang Line Southern Elevated Section",
    "notes": "Metro",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 19000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 42,
    "name": "Cangzhou–Dezhou Grand Bridge\nBeijing–Shanghai High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 18200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 43,
    "name": "Crimean Bridge (Kerch Strait)",
    "notes": "Road & Rail",
    "country": "Russia\n Crimea",
    "dimensions": [
      {
        "value": 18100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 44,
    "name": "Aérotrain Test Track\nNo longer in use",
    "notes": "Rail\n(prototype)",
    "country": "France",
    "dimensions": [
      {
        "value": 18000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 45,
    "name": "Vasco da Gama Bridge",
    "notes": "Highway",
    "country": "Portugal",
    "dimensions": [
      {
        "value": 17185,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 46,
    "name": "Bonnet Carré Spillway Bridge of I-10\n(I-10 over Bonnet Carré Spillway in Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 17702,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 47,
    "name": "Pingtan Strait Rail-Road Bridge [zh]",
    "notes": "Highway & Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 16322,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 48,
    "name": "Green line (Taichung Metro)",
    "notes": "Metro",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 15650,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 49,
    "name": "Eastern Freeway South Stretch",
    "notes": "Expressway",
    "country": "India",
    "dimensions": [
      {
        "value": 15595,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 50,
    "name": "Cross Beijing Ring Roads Bridge\nBeijing-Tianjin Intercity Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 15595,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 51,
    "name": "Manila LRT Line 2",
    "notes": "Metro",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 13800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 52,
    "name": "Penang Bridge",
    "notes": "Highway",
    "country": "Malaysia",
    "dimensions": [
      {
        "value": 13500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 53,
    "name": "Kam Sheung Road-Tuen Mun viaduct (part of West Rail line)",
    "notes": "Rail",
    "country": "Hong Kong",
    "dimensions": [
      {
        "value": 13400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 54,
    "name": "Louisiana Highway 1 Bridge\n(State Highway 1 between Golden Meadow, Louisiana and Port Fourchon, Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 13300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 55,
    "name": "Rio–Niterói Bridge",
    "notes": "Road",
    "country": "Brazil",
    "dimensions": [
      {
        "value": 13290,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 56,
    "name": "Great Seto Bridge",
    "notes": "Highway & Rail",
    "country": "Japan",
    "dimensions": [
      {
        "value": 13100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 57,
    "name": "Taoyuan Airport MRT\n(2k+900~15k+930 from Airport Terminal 1 station)",
    "notes": "Metro",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 13030,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 58,
    "name": "Bhumibol Bridge",
    "notes": "Road",
    "country": "Thailand",
    "dimensions": [
      {
        "value": 13000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 59,
    "name": "Confederation Bridge\n(Trans-Canada Highway across Northumberland Strait between Prince Edward Island and New Brunswick)",
    "notes": "Highway",
    "country": "Canada",
    "dimensions": [
      {
        "value": 12900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 60,
    "name": "Nansha Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 12891,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 61,
    "name": "Jubilee Parkway\n(I-10 over Mobile Bay in Alabama)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 12875,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 62,
    "name": "Bali Mandara Toll Road",
    "notes": "Road",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 12700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 63,
    "name": "Rudong Yangkou Yellow Sea Bridge",
    "notes": "Road",
    "country": "China",
    "dimensions": [
      {
        "value": 12600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 64,
    "name": "Digha-AIIMS Elevated Tollway",
    "notes": "Highway",
    "country": "India",
    "dimensions": [
      {
        "value": 12500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 65,
    "name": "Rosario-Victoria Bridge",
    "notes": "Road",
    "country": "Argentina",
    "dimensions": [
      {
        "value": 12282,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 66,
    "name": "North South MRT line (Lentor North - Marsiling)\nMass Rapid Transit (Singapore)",
    "notes": "Metro",
    "country": "Singapore",
    "dimensions": [
      {
        "value": 12200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 67,
    "name": "Emsland test facility",
    "notes": "Maglev",
    "country": "Germany",
    "dimensions": [
      {
        "value": 12000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 68,
    "name": "Ir. Wiyoto Wiyono Toll Road[citation needed]",
    "notes": "Highway",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 12000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 69,
    "name": "Nanjing Qinhuai River Bridge\nBeijing-Shanghai High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 12000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 70,
    "name": "Third Mainland Bridge",
    "notes": "Road",
    "country": "Nigeria",
    "dimensions": [
      {
        "value": 11800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 71,
    "name": "Qingshuihe Bridge\nQingzang Railway",
    "notes": "Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 11700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 72,
    "name": "Lezíria Bridge",
    "notes": "Highway",
    "country": "Portugal",
    "dimensions": [
      {
        "value": 11670,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 73,
    "name": "P.V. Narasimha Rao Elevated Expressway",
    "notes": "Highway",
    "country": "India",
    "dimensions": [
      {
        "value": 11600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 74,
    "name": "NAIA Expressway",
    "notes": "Expressway",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 11600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 75,
    "name": "Taoyuan Airport MRT\n(19k+600~31k+100 from Airport Terminal 1 station)",
    "notes": "Metro",
    "country": "Taiwan",
    "dimensions": [
      {
        "value": 11500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 76,
    "name": "San Mateo–Hayward Bridge\n(State Route 92 over San Francisco Bay in California)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 11270,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 77,
    "name": "Hutong Yangtze River Bridge",
    "notes": "Expressway & Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 11072,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 78,
    "name": "Zhenjiang Beijing–Hangzhou Canal Bridge\nBeijing-Shanghai High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 11000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 79,
    "name": "Seven Mile Bridge\n(Overseas Highway/US 1 in the Florida Keys)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 10887,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 80,
    "name": "Shashangou Bridge",
    "notes": "Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 10690,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 81,
    "name": "Shandong-Henan Yellow River Bridge",
    "notes": "Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 10282,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 82,
    "name": "Jiaxing-Shaoxing Sea Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 10138,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 83,
    "name": "Wuhu Yangtze River Bridge",
    "notes": "Expressway & Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 10020,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 84,
    "name": "Bangalore Elevated Tollway",
    "notes": "Highway",
    "country": "India",
    "dimensions": [
      {
        "value": 9945,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 85,
    "name": "Shanghai Yangtze River Bridge",
    "notes": "Road (& future Rail)",
    "country": "China",
    "dimensions": [
      {
        "value": 9970,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 86,
    "name": "General W.K. Wilson Jr. Bridge\n(I-65 over the Mobile–Tensaw River Delta in Alabama)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 9786,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 87,
    "name": "Viadotto Coltano\n(A12 over the Scolmatore dell'Arno channel and agricultural lowlands in Tuscany)",
    "notes": "Expressway",
    "country": "Italy",
    "dimensions": [
      {
        "value": 9619,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 88,
    "name": "Norfolk Southern Lake Pontchartrain Bridge\n(Norfolk Southern rail line across Lake Pontchartrain in Louisiana)",
    "notes": "Rail",
    "country": "United States",
    "dimensions": [
      {
        "value": 9300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 89,
    "name": "TransJakarta Corridor 13",
    "notes": "Elevated BRT",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 9300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 90,
    "name": "Nanjing Dashengguan Yangtze River Bridge",
    "notes": "High-speed rail & Metro",
    "country": "China",
    "dimensions": [
      {
        "value": 9273,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 91,
    "name": "Dhola–Sadiya Bridge",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 9150,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 92,
    "name": "Chacahoula Swamp Bridge\n(US 90 across Chacahoula Swamp in Terrebonne Parish, Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 9005,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 93,
    "name": "Cebu-Cordova Link Expressway",
    "notes": "Highway",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 8900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 94,
    "name": "Frank Davis \"Naturally N'Awlins\" Memorial Bridge\n(I-10 over Lake Pontchartrain in Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 8851,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 95,
    "name": "Richmond–San Rafael Bridge\n(I-580 over San Francisco Bay in California)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 8851,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 96,
    "name": "General Rafael Urdaneta Bridge",
    "notes": "Road",
    "country": "Venezuela",
    "dimensions": [
      {
        "value": 8678,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 97,
    "name": "Metro Bus corridor- Rawalpindi Section (Rawalpindi)",
    "notes": "Elevated BRT",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 8600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 98,
    "name": "Virginia Dare Memorial Bridge\n(US 64 Bypass over Croatan Sound in North Carolina)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 8369,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 99,
    "name": "Yangpu Bridge",
    "notes": "Road",
    "country": "China",
    "dimensions": [
      {
        "value": 8354,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 100,
    "name": "NLEX Harbor Link (Segment 10)\nNorth Luzon Expressway",
    "notes": "Expressway",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 8250,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 101,
    "name": "Xiasha Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 8230,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 102,
    "name": "Sutong Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 8206,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 103,
    "name": "Mackinac Bridge\n(I-75 over the Straits of Mackinac in Michigan)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 8038,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 104,
    "name": "LaBranche Wetlands Bridge\n(I-310 over the LaBranche Wetlands in St. Charles Parish, Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 7902,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 105,
    "name": "Öresund Bridge",
    "notes": "Highway & Rail",
    "country": "Denmark and\n Sweden",
    "dimensions": [
      {
        "value": 7845,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 106,
    "name": "Maestri Bridge\n(US 11 over Lake Pontchartrain in Louisiana)",
    "notes": "Road",
    "country": "United States",
    "dimensions": [
      {
        "value": 7693,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 107,
    "name": "Jiujiang Yangtze River Bridge",
    "notes": "Highway & Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 7675,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 108,
    "name": "Gwangan Bridge",
    "notes": "Road",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 7420,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 109,
    "name": "Viadotto Fichera\n(A19 over the Imera Settentrionale river valley and coastal plain in Sicily)",
    "notes": "Expressway",
    "country": "Italy",
    "dimensions": [
      {
        "value": 7350,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 110,
    "name": "Jamal Abdul Nasser Bridge[citation needed]",
    "notes": "Highway",
    "country": "Kuwait",
    "dimensions": [
      {
        "value": 7333,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 111,
    "name": "Seohae Bridge",
    "notes": "Highway",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 7310,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 112,
    "name": "First Saecheonnyeon Bridge [ko]",
    "notes": "Road",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 7260,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 113,
    "name": "Tongjiang-Nizhneleninskoye railway bridge",
    "notes": "Rail",
    "country": "China\n Russia",
    "dimensions": [
      {
        "value": 7194,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 114,
    "name": "Ershilipu Bridge\nLanzhou–Urumqi High-Speed Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 7093,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 115,
    "name": "James River Bridge\n(US 17 over the James River in Virginia)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 7071,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 116,
    "name": "Huey P. Long Bridge\n(New Orleans Public Belt Railroad over Mississippi River in Louisiana)\n(Railroad bridge. Flanking US 90 road section is shorter than 3 km)",
    "notes": "Rail",
    "country": "United States",
    "dimensions": [
      {
        "value": 7009,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 117,
    "name": "Chesapeake Bay Bridge\n(US 50 and US 301 over Chesapeake Bay in Maryland)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 6946,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 118,
    "name": "Gardiner Expressway\n(6.8-km Gardiner Expressway viaduct in Toronto, Ontario)",
    "notes": "Highway",
    "country": "Canada",
    "dimensions": [
      {
        "value": 6800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 119,
    "name": "Dalian Xinghai Bay Bridge [fr]",
    "notes": "Highway",
    "country": "China",
    "dimensions": [
      {
        "value": 6800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 120,
    "name": "Great Belt Bridge (Eastern)",
    "notes": "Highway",
    "country": "Denmark",
    "dimensions": [
      {
        "value": 6790,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 121,
    "name": "Nanjing Yangtze River Bridge",
    "notes": "Highway & Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 6772,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 122,
    "name": "Xiangshan Harbor Bridge",
    "notes": "Highway",
    "country": "China",
    "dimensions": [
      {
        "value": 6761,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 123,
    "name": "Great Belt Bridge (Western)",
    "notes": "Highway & Rail",
    "country": "Denmark",
    "dimensions": [
      {
        "value": 6611,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 124,
    "name": "Sunshine Skyway Bridge\n(I-275 over Tampa Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 6598,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 125,
    "name": "Thanlwin Bridge (Mawlamyaing)",
    "notes": "Road & Rail",
    "country": "Myanmar",
    "dimensions": [
      {
        "value": 6589,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 126,
    "name": "St. George Island Bridge\n(State Road 300 over Apalachicola Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 6588,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 127,
    "name": "Astoria–Megler Bridge\n(US 101 across the Columbia River between Oregon and Washington)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 6545,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 128,
    "name": "Saale-Elster Viaduct\nErfurt–Leipzig/Halle high-speed railway",
    "notes": "High-speed rail",
    "country": "Germany",
    "dimensions": [
      {
        "value": 6465,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 129,
    "name": "Wufengshan Yangtze River Bridge",
    "notes": "Expressway & Rail",
    "country": "China",
    "dimensions": [
      {
        "value": 6409,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 130,
    "name": "Pakokku Bridge",
    "notes": "Road & Rail",
    "country": "Myanmar",
    "dimensions": [
      {
        "value": 6278,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 131,
    "name": "Ayeyarwady-Nyaungdon Bridge",
    "notes": "Road & Rail",
    "country": "Myanmar",
    "dimensions": [
      {
        "value": 6262,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 132,
    "name": "Dibang River Bridge",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 6200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 133,
    "name": "Öland Bridge",
    "notes": "Highway",
    "country": "Sweden",
    "dimensions": [
      {
        "value": 6072,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 134,
    "name": "Bleiswijk Railway Viaduct[citation needed]",
    "notes": "High-speed rail",
    "country": "Netherlands",
    "dimensions": [
      {
        "value": 6000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 135,
    "name": "Hernando de Soto Bridge\n(I-40 over the Mississippi River between Arkansas and Tennessee)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 5954,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 136,
    "name": "President Bridge",
    "notes": "Road",
    "country": "Russia",
    "dimensions": [
      {
        "value": 5825,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 137,
    "name": "Mid-Bay Bridge\n(State Road 293 over Choctawhatchee Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 5793,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 138,
    "name": "Mahatma Gandhi Setu",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 5750,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 139,
    "name": "Pulaski Skyway\n(US 1/US 9 over the Hackensack and Passaic rivers in New Jersey)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 5636,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 140,
    "name": "Garden City Skyway\n(Queen Elizabeth Way over the Welland Canal in Ontario)",
    "notes": "Highway",
    "country": "Canada",
    "dimensions": [
      {
        "value": 5633,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 141,
    "name": "Albemarle Sound Bridge\n(State Highways 32, 37 and 94 over Albemarle Sound in North Carolina)",
    "notes": "Road",
    "country": "United States",
    "dimensions": [
      {
        "value": 5627,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 142,
    "name": "Bromford Viaduct",
    "notes": "Road",
    "country": "United Kingdom",
    "dimensions": [
      {
        "value": 5600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 143,
    "name": "Bandra-Worli Sea Link",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 5575,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 144,
    "name": "Hong Kong–Shenzhen Western Corridor",
    "notes": "Expressway",
    "country": "Hong Kong",
    "dimensions": [
      {
        "value": 5545,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 145,
    "name": "Island Eastern Corridor (Causeway Bay to Quarry Bay section)",
    "notes": "Road",
    "country": "Hong Kong",
    "dimensions": [
      {
        "value": 5500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 146,
    "name": "Tan Vu-Lach Huyen Bridge",
    "notes": "Highway",
    "country": "Vietnam",
    "dimensions": [
      {
        "value": 5440,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 147,
    "name": "Viadotto Morello\n(A19 over the Imera Meridionale river valley in Sicily)",
    "notes": "Expressway",
    "country": "Italy",
    "dimensions": [
      {
        "value": 5440,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 148,
    "name": "Suramadu Bridge (cross Madura Strait)",
    "notes": "Road",
    "country": "Indonesia",
    "dimensions": [
      {
        "value": 5438,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 149,
    "name": "Dauphin Island Bridge\n(State Route 193 over the Gulf Intracoastal Waterway in Alabama)",
    "notes": "Road",
    "country": "United States",
    "dimensions": [
      {
        "value": 5430,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 150,
    "name": "Xinkai River Bridge of Beijing-Tianjin Intercity Railway",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 5371,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 151,
    "name": "Libertador General San Martín Bridge",
    "notes": "Road",
    "country": "Uruguay and\n Argentina",
    "dimensions": [
      {
        "value": 5366,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 152,
    "name": "Yolo Causeway\n(I-80 over Yolo Bypass in California)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 5200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 153,
    "name": "King Fahd Causeway Bridge I",
    "notes": "Road",
    "country": "Saudi Arabia and\n Bahrain",
    "dimensions": [
      {
        "value": 5194,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 154,
    "name": "Second Severn Crossing",
    "notes": "Road",
    "country": "United Kingdom",
    "dimensions": [
      {
        "value": 5128,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 155,
    "name": "Zeeland Bridge",
    "notes": "Road",
    "country": "Netherlands",
    "dimensions": [
      {
        "value": 5022,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 156,
    "name": "Bolte Bridge",
    "notes": "Road",
    "country": "Australia",
    "dimensions": [
      {
        "value": 5000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 157,
    "name": "Malir River Bridge",
    "notes": "Road",
    "country": "Pakistan",
    "dimensions": [
      {
        "value": 5000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 158,
    "name": "Candaba Viaduct",
    "notes": "Highway",
    "country": "Philippines",
    "dimensions": [
      {
        "value": 5000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 159,
    "name": "Tappan Zee Bridge\n(I-87 and I-287 over the Hudson River in New York)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4989,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 160,
    "name": "Bangabandhu Bridge",
    "notes": "Road & Rail",
    "country": "Bangladesh",
    "dimensions": [
      {
        "value": 4980,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 161,
    "name": "Buckman Bridge\n(I-295 over the St. Johns River in Florida south of Jacksonville)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4968,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 162,
    "name": "Bogibeel Bridge",
    "notes": "Road & Rail",
    "country": "India",
    "dimensions": [
      {
        "value": 4940,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 163,
    "name": "Howard Frankland Bridge (Southbound)\n(I-275 over Old Tampa Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4846,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 164,
    "name": "Howard Frankland Bridge (Northbound)\n(I-275 over Old Tampa Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4838,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 165,
    "name": "Wright Memorial Bridge\n(US 158 over Currituck Sound in North Carolina)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4828,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 166,
    "name": "North–South Motorway (over Grand Junction Road in Adelaide)",
    "notes": "Expressway",
    "country": "Australia",
    "dimensions": [
      {
        "value": 4800,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 167,
    "name": "Pensacola Bay Bridge\n(US 98 over Pensacola Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4767,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 168,
    "name": "Viaducto del Manglar",
    "notes": "Road",
    "country": "Colombia",
    "dimensions": [
      {
        "value": 4730,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 169,
    "name": "Ogooué Bridge",
    "notes": "Road",
    "country": "Gabon",
    "dimensions": [
      {
        "value": 4707,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 170,
    "name": "Vikramshila Setu",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 4700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 171,
    "name": "Dingziwan Cross-Sea Bridge",
    "notes": "Highway",
    "country": "China",
    "dimensions": [
      {
        "value": 4700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 172,
    "name": "Vembanad Rail Bridge, Kochi",
    "notes": "Rail",
    "country": "India",
    "dimensions": [
      {
        "value": 4620,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 173,
    "name": "Digha–Sonpur Bridge",
    "notes": "Road & Rail",
    "country": "India",
    "dimensions": [
      {
        "value": 4655,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 174,
    "name": "Nkomi Laguna Bridge",
    "notes": "Road",
    "country": "Gabon",
    "dimensions": [
      {
        "value": 4577,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 175,
    "name": "Lindsay C. Warren Bridge/Alligator River Bridge\n(US 64 over the Alligator River in North Carolina)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4550,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 176,
    "name": "Gandy Bridge (Eastbound)\n(US 92 over Old Tampa Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4529,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 177,
    "name": "Marc Basnight Bridge\n(State Highway 12 over Oregon Inlet in North Carolina)",
    "notes": "Road",
    "country": "United States",
    "dimensions": [
      {
        "value": 4506,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 178,
    "name": "Sault Ste. Marie International Bridge\n(over the St. Marys River between I-75 in Michigan and Ontario)",
    "notes": "Highway",
    "country": "United States and\n Canada",
    "dimensions": [
      {
        "value": 4480,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 179,
    "name": "Yeongjong Bridge",
    "notes": "Road",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 4420,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 180,
    "name": "Sydney Metro Northwest Skytrain",
    "notes": "Metro",
    "country": "Australia",
    "dimensions": [
      {
        "value": 4413,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 181,
    "name": "Jingzhou Yangtze River Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 4398,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 182,
    "name": "Aqua Bridge (Tokyo Bay Aqua-Line)",
    "notes": "Road",
    "country": "Japan",
    "dimensions": [
      {
        "value": 4384,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 183,
    "name": "Amur Bay Bridge",
    "notes": "Road",
    "country": "Russia",
    "dimensions": [
      {
        "value": 4364,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 184,
    "name": "Arrah–Chhapra Bridge",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 4350,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 185,
    "name": "Ponte Salgueiro Maia [pt]",
    "notes": "Highway",
    "country": "Portugal",
    "dimensions": [
      {
        "value": 4300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 186,
    "name": "Bayside Bridge\n(County Road 611 over Old Tampa Bay in Pinellas County, Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4270,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 187,
    "name": "Hochstraße Elbmarsch",
    "notes": "Road",
    "country": "Germany",
    "dimensions": [
      {
        "value": 4258,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 188,
    "name": "Bubiyan Island Railway Bridge",
    "notes": "Rail",
    "country": "Kuwait",
    "dimensions": [
      {
        "value": 4245,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 189,
    "name": "Commodore Barry Bridge\n(US 322 over the Delaware River between New Jersey and Pennsylvania)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4240,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 190,
    "name": "Gandy Bridge (Westbound)\n(US 92 over Old Tampa Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4226,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 191,
    "name": "Escambia Bay Bridge\n(I-10 over Escambia Bay in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4224,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 192,
    "name": "Viadotto Cannatello\n(A19 over the Imera Meridionale river valley in Sicily)",
    "notes": "Expressway",
    "country": "Italy",
    "dimensions": [
      {
        "value": 4220,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 193,
    "name": "Verrazano-Narrows Bridge\n(I-278 across The Narrows between Brooklyn and Staten Island, New York)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4176,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 194,
    "name": "Greenville Bridge\n(US 278 and US 82 over the Mississippi River between Mississippi and Arkansas)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4133,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 195,
    "name": "Godavari Fourth Bridge",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 4135,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 196,
    "name": "Yangsigang Yangtze River Bridge",
    "notes": "Highway",
    "country": "China",
    "dimensions": [
      {
        "value": 4130,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 197,
    "name": "Godavari Road-Cum-Rail Bridge\n",
    "notes": "Road & Rail",
    "country": "India",
    "dimensions": [
      {
        "value": 4100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 198,
    "name": "Crescent City Connection\n(US 90 Business over the Mississippi River in Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4093,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 199,
    "name": "Anghel Saligny Bridge\n(over the Danube)",
    "notes": "Rail",
    "country": "Romania",
    "dimensions": [
      {
        "value": 4088,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 200,
    "name": "Arthur Ravenel, Jr. Bridge\n(US 17 over the Cooper River in South Carolina)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4023,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 201,
    "name": "Kurushima-Kaikyō Bridge",
    "notes": "Highway",
    "country": "Japan",
    "dimensions": [
      {
        "value": 4015,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 202,
    "name": "Fred Hartman Bridge\n(State Highway 146 over the Houston Ship Channel in Texas)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 4000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 203,
    "name": "Vicksburg Bridge / Chris Smith Bridge\n(I-20 over the Mississippi River between Mississippi and Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3954,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 204,
    "name": "Köhlbrandbrücke",
    "notes": "Road",
    "country": "Germany",
    "dimensions": [
      {
        "value": 3940,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 205,
    "name": "John James Audubon Bridge (Mississippi River)\n(State Highway 10 over Mississippi River in Louisiana)",
    "notes": "Road",
    "country": "United States",
    "dimensions": [
      {
        "value": 3927,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 206,
    "name": "Akashi Kaikyō Bridge",
    "notes": "Highway",
    "country": "Japan",
    "dimensions": [
      {
        "value": 3911,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 207,
    "name": "Amizade Bridge",
    "notes": "Road",
    "country": "Macau",
    "dimensions": [
      {
        "value": 3900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 208,
    "name": "Suez Canal Bridge",
    "notes": "Road",
    "country": "Egypt",
    "dimensions": [
      {
        "value": 3900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 209,
    "name": "Lupu Bridge",
    "notes": "Road",
    "country": "China",
    "dimensions": [
      {
        "value": 3900,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 210,
    "name": "Yuribey Bridge",
    "notes": "Rail",
    "country": "Russia",
    "dimensions": [
      {
        "value": 3892.9,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 211,
    "name": "Khabarovsk Bridge",
    "notes": "Road & Rail",
    "country": "Russia",
    "dimensions": [
      {
        "value": 3890.5,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 212,
    "name": "The First Kitakami River Bridge\nTōhoku Shinkansen",
    "notes": "High-speed rail",
    "country": "Japan",
    "dimensions": [
      {
        "value": 3868,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 213,
    "name": "Zacatal Bridge",
    "notes": "Road",
    "country": "Mexico",
    "dimensions": [
      {
        "value": 3861,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 214,
    "name": "Ponte della Libertà",
    "notes": "Road & Rail",
    "country": "Italy",
    "dimensions": [
      {
        "value": 3850,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 215,
    "name": "Queen Isabella Causeway\n(State Park Road 100 over Laguna Madre in Texas)",
    "notes": "Road",
    "country": "United States",
    "dimensions": [
      {
        "value": 3810,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 216,
    "name": "Santhià Viaduct",
    "notes": "Rail",
    "country": "Italy",
    "dimensions": [
      {
        "value": 3782,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 217,
    "name": "Sky Gate Bridge R [ja]\nKansai Airport Line / Nankai Airport Line",
    "notes": "Road & Rail",
    "country": "Japan",
    "dimensions": [
      {
        "value": 3750,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 218,
    "name": "CNR Bonnet Carré Spillway-McComb Bridge\n(Canadian National Railway over Bonnet Carré Spillway in Louisiana)",
    "notes": "Rail",
    "country": "United States",
    "dimensions": [
      {
        "value": 3702,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 219,
    "name": "Rollemberg–Vuolo Road–Railway Bridge",
    "notes": "Road & Rail",
    "country": "Brazil",
    "dimensions": [
      {
        "value": 3700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 220,
    "name": "Nhật Tân Bridge",
    "notes": "Highway",
    "country": "Vietnam",
    "dimensions": [
      {
        "value": 3700,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 221,
    "name": "Munger Ganga Bridge",
    "notes": "Road & Rail",
    "country": "India",
    "dimensions": [
      {
        "value": 3692,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 222,
    "name": "Vinh Tuy Bridge",
    "notes": "Road",
    "country": "Vietnam",
    "dimensions": [
      {
        "value": 3690,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 223,
    "name": "Dona Ana Bridge",
    "notes": "Rail",
    "country": "Mozambique",
    "dimensions": [
      {
        "value": 3670,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 224,
    "name": "Walt Whitman Bridge\n(I-76 over Delaware River between Pennsylvania and New Jersey)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3652,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 225,
    "name": "Humen Pearl River Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 3618,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 226,
    "name": "Ayrton Senna Bridge",
    "notes": "Road",
    "country": "Brazil",
    "dimensions": [
      {
        "value": 3607,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 227,
    "name": "Richard I. Bong Memorial Bridge\n(U.S. Route 2 over Saint Louis River between Minnesota and Wisconsin)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 228,
    "name": "Panipat Elevated Expressway",
    "notes": "Expressway",
    "country": "India",
    "dimensions": [
      {
        "value": 3600,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 229,
    "name": "Rio Negro Bridge",
    "notes": "Road",
    "country": "Brazil",
    "dimensions": [
      {
        "value": 3595,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 230,
    "name": "Viadotto Fadalto Ovest\n(A27 in Veneto)",
    "notes": "Highway",
    "country": "Italy",
    "dimensions": [
      {
        "value": 3567,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 231,
    "name": "Irabu Bridge R [ja]\nBetween Miyako Island and Irabu Island, Ryukyu Island",
    "notes": "Road",
    "country": "Japan",
    "dimensions": [
      {
        "value": 3540,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 232,
    "name": "Thang Long Bridge",
    "notes": "Road",
    "country": "Vietnam",
    "dimensions": [
      {
        "value": 3500,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 233,
    "name": "Viadotto Fadalto Est\n(A27 in Veneto)",
    "notes": "Highway",
    "country": "Italy",
    "dimensions": [
      {
        "value": 3485,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 234,
    "name": "Claiborne Pell Newport Bridge\n(Rhode Island Route 138 over Narragansett Bay in Newport, Rhode Island)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3428,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 235,
    "name": "Jacques Cartier Bridge (Montreal)",
    "notes": "Highway",
    "country": "Canada",
    "dimensions": [
      {
        "value": 3425.6,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 236,
    "name": "San Diego–Coronado Bridge\n(State Route 75 over San Diego Bay in California)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3407,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 237,
    "name": "Champlain Bridge (Montreal)\n(Autoroutes 10, 15, and 20 over the Saint Lawrence River in Quebec)",
    "notes": "Highway (& future light rail)",
    "country": "Canada",
    "dimensions": [
      {
        "value": 3400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 238,
    "name": "Mozambique Island Bridge",
    "notes": "Road",
    "country": "Mozambique",
    "dimensions": [
      {
        "value": 3400,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 239,
    "name": "Lake Jesup Bridge[citation needed]\n(State Road 417 over Lake Jesup in Florida)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3379,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 240,
    "name": "Viadotto Imera II aka Himera II aka Imera Sud\n(A19 over the Imera Meridionale river valley in Sicily)",
    "notes": "Expressway",
    "country": "Italy",
    "dimensions": [
      {
        "value": 3370,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 241,
    "name": "Busan Harbor Bridge",
    "notes": "Road",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 3368,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 242,
    "name": "Saint-Nazaire Bridge",
    "notes": "Road",
    "country": "France",
    "dimensions": [
      {
        "value": 3356,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 243,
    "name": "King Fahd Causeway Bridge II",
    "notes": "Road",
    "country": "Saudi Arabia and\n Bahrain",
    "dimensions": [
      {
        "value": 3334,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 244,
    "name": "Third Bridge",
    "notes": "Road",
    "country": "Brazil",
    "dimensions": [
      {
        "value": 3300,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 245,
    "name": "Delaware Memorial Bridge (Westbound)\n(I-295 and US 40 over the Delaware River from New Jersey to Delaware)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3291,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 246,
    "name": "Delaware Memorial Bridge (Eastbound)\n(I-295 and US 40 over the Delaware River from Delaware to New Jersey)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3281,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 247,
    "name": "La Unidad Bridge",
    "notes": "Road",
    "country": "Mexico",
    "dimensions": [
      {
        "value": 3277,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 248,
    "name": "Tay Rail Bridge",
    "notes": "Rail",
    "country": "United Kingdom",
    "dimensions": [
      {
        "value": 3264,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 249,
    "name": "Hale Boggs Memorial Bridge\n(I-310 over the Mississippi River in Louisiana)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3261,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 250,
    "name": "Chahlari Ghat Bridge",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 3260,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 251,
    "name": "Dames Point Bridge\n(I-295 over the St. Johns River in Florida east of Jacksonville)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3245,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 252,
    "name": "Pumarejo bridge",
    "notes": "Highway",
    "country": "Colombia",
    "dimensions": [
      {
        "value": 3237,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 253,
    "name": "Great South Bay Bridge\n(Robert Moses Causeway over Great South Bay in Long Island, New York)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3206,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 254,
    "name": "Nurly Zhol Bridge R [ru]",
    "notes": "Road",
    "country": "Kazakhstan",
    "dimensions": [
      {
        "value": 3200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 255,
    "name": "Macleay River Bridge",
    "notes": "Road",
    "country": "Australia",
    "dimensions": [
      {
        "value": 3200,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 256,
    "name": "Storstrøm Bridge",
    "notes": "Road & Rail",
    "country": "Denmark",
    "dimensions": [
      {
        "value": 3199,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 257,
    "name": "Third Mandovi Bridge",
    "notes": "Highway",
    "country": "India",
    "dimensions": [
      {
        "value": 3190,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 258,
    "name": "Second Orinoco crossing (Orinoquia Bridge)",
    "notes": "Road & Rail",
    "country": "Venezuela",
    "dimensions": [
      {
        "value": 3156,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 259,
    "name": "San Francisco–Oakland Bay Bridge\n(I-80 over San Francisco Bay in California)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3141,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 260,
    "name": "Bridge to Russky Island",
    "notes": "Road",
    "country": "Russia",
    "dimensions": [
      {
        "value": 3100,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 261,
    "name": "La Pepa Bridge",
    "notes": "Road",
    "country": "Spain",
    "dimensions": [
      {
        "value": 3092,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 262,
    "name": "Outerbridge Crossing",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3091,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 263,
    "name": "Thanh Tri Bridge",
    "notes": "Road",
    "country": "Vietnam",
    "dimensions": [
      {
        "value": 3084,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 264,
    "name": "Heishipu Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 3068,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 265,
    "name": "Jawahar Setu",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 3061,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 266,
    "name": "Talmadge Memorial Bridge\n(US 17 over the Savannah River in Georgia)",
    "notes": "Highway",
    "country": "United States",
    "dimensions": [
      {
        "value": 3060,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 267,
    "name": "Mokpo Bridge [ko]",
    "notes": "Road",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 3060,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 268,
    "name": "Nehru Setu",
    "notes": "Rail",
    "country": "India",
    "dimensions": [
      {
        "value": 3059,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 269,
    "name": "Oleron Viaduct [fr]",
    "notes": "Road",
    "country": "France",
    "dimensions": [
      {
        "value": 3027,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 270,
    "name": "Kolia Bhomora Setu",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 3015,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 271,
    "name": "Ponte Conde de Linhares",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 3000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 272,
    "name": "Jiangyin Bridge",
    "notes": "Expressway",
    "country": "China",
    "dimensions": [
      {
        "value": 3000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 273,
    "name": "Korthi-Kolhar Bridge",
    "notes": "Road",
    "country": "India",
    "dimensions": [
      {
        "value": 3000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 274,
    "name": "C310 Viaduct",
    "notes": "High-speed rail",
    "country": "China",
    "dimensions": [
      {
        "value": 3000,
        "unit": "meters",
        "type": "length"
      }
    ]
  },
  {
    "rank": 275,
    "name": "Çanakkale 1915 Bridge",
    "notes": "Highway*",
    "country": "Turkey",
    "dimensions": [
      {
        "value": 4608,
        "unit": "meters",
        "type": "length"
      }
    ]
  }
]