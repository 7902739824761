[
  {
    "rank": "1",
    "name": "Goliath",
    "region": "Six Flags Great America",
    "country": "United States",
    "dimensions": [
      {
        "value": 116,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "2",
    "name": "Wildfire",
    "region": "Kolmården Wildlife Park",
    "country": "Sweden",
    "dimensions": [
      {
        "value": 115.1,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "3",
    "name": "El Toro",
    "region": "Six Flags Great Adventure",
    "country": "United States",
    "dimensions": [
      {
        "value": 110,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "4",
    "name": "Colossos - Kampf der Giganten",
    "region": "Heide Park",
    "country": "Germany",
    "dimensions": [
      {
        "value": 110.1,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "5",
    "name": "Outlaw Run",
    "region": "Silver Dollar City",
    "country": "United States",
    "dimensions": [
      {
        "value": 109,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "6",
    "name": "The Voyage",
    "region": "Holiday World & Splashin' Safari",
    "country": "United States",
    "dimensions": [
      {
        "value": 108,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "7",
    "name": "The Boss",
    "region": "Six Flags St. Louis",
    "country": "United States",
    "dimensions": [
      {
        "value": 106.7,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "8",
    "name": "American Eagle",
    "region": "Six Flags Great America",
    "country": "United States",
    "dimensions": [
      {
        "value": 106,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "9",
    "name": "The Beast",
    "region": "Kings Island",
    "country": "United States",
    "dimensions": [
      {
        "value": 104.3,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  },
  {
    "rank": "10",
    "name": "T Express",
    "region": "Everland",
    "country": "South Korea",
    "dimensions": [
      {
        "value": 104,
        "unit": "kilometers per hour",
        "type": "speed"
      }
    ]
  }
]